import { LocalizationKeys } from './constant';

const en: LocalizationKeys = {
  company: 'Company',
  companies: 'Companies',
  propertyManagers: 'Property Managers',
  propertyManager: 'Property Manager',
  homeOwners: 'Home Owners',
  homeOwner: 'Home Owner',
  dashboard: 'Dashboard',
  totalCompany: 'Total Company',
  totalCompanies: 'Total Companies',
  totalManager: 'Total Manager',
  totalManagers: 'Total Managers',
  totalOwner: 'Total Owner',
  totalOwners: 'Total Owners',
  totalFolder: 'Total Folder',
  totalFolders: 'Total Folders',
  totalHouse: 'Total House',
  totalHouses: 'Total Houses',
  addNewCompany: 'Add New Company',
  addCompany: 'Add Company',
  editCompany: 'Edit Company',
  addCompanySuccessfully: 'Company added successfully.',
  viewCompany: 'View Company',
  viewPropertyManager: 'View Property Manager',
  viewHomeOwner: 'View Home Owner',
  house: 'House',
  houses: 'Houses',
  folder: 'Folder',
  folders: 'Folders',
  allPropertyAccess: 'All Property Access',
  allFolderAccess: 'All Folder Access',
  email: 'Email',
  companyName: 'Company Name',
  userName: 'User Name',
  communicationEmail: 'Communication Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  clearFilter: 'Clear Filter',
  noDataFound: 'No Data Found',
  help: 'Help',
  view: 'View',
  edit: 'Edit',
  deleteData: 'Delete',
  name: 'Name',
  status: 'Status',
  action: 'Action',
  cancelButton: 'Cancel',
  submitButton: 'Submit',
  updateButton: 'Update',
  closeButton: 'Close',
  yesButton: 'Yes',
  noButton: 'No',
  areYousureToChangeStatus: 'Are you sure you want to change status?',
  areYouSureToDelete: 'Are you sure you want to delete?',
  addManager: 'Add Manager',
  editManager: 'Edit Manager',
  pleaseSelectCompany: 'Please select company',
  houseName: 'House Name',
  editHomeOwner: 'Edit Home Owner',
  //templatePage
  template: 'Template',
  templates: 'Templates',
  templateName: 'Template Name',
  addTemplate: 'Add Template',
  editTemplate: 'Edit Template',
  notification: 'Notification',
  areYouSuretoOverrideTheChanges:
    'Are you sure you want to override the changes ?',
  templateUpdatedsuccessfully: 'Template updated successfully.',
  companyAddedSuccessfully: 'Company added successfully.',
  companyUpdatedSuccessfully: 'Company updated successfully.',
  companyDeletedSuccessfully: 'Company deleted successfully.',
  statusDeactivatedSuccessfully: 'Status deactivated successfully.',
  statusActivatedSuccessfully: 'Status activated successfully.',
  managerUpdatedSuccessfully: 'Manager updated successfully.',
  managerDeletedSuccessfully: 'Manager deleted successfully.',
  managerAddedSuccessfully: 'Manager added successfully.',
  homeOwnerUpdatedSuccessfully: 'Home owner updated successfully.',
  homeOwnerDeletedSuccessfully: 'Home owner deleted successfully.',
  categoryAddedSuccessfully: 'Category added successfully.',
  categoryUpdatedSuccessfully: 'Category updated successfully.',
  templateAddedSuccessfully: 'Template added successfully.',
  templateDeletedSuccessfully: 'Template deleted successfully.',
  pleaseSelectCompanyFromDropdown: 'Please select company from dropdown',
  ads: 'Ads',
  ad: 'Ad',
  addAd: 'Add ad',
  editAd: 'Edit Ad',
  manageAds: 'Manage Ads',
  viewAd: 'View Ad',
  adTitle: 'Ad Title',
  startDate: 'Start Date',
  endDate: 'End Date',
  link: 'Link',
  webImage: 'Web Image',
  mobileImage: 'Mobile Image',
  displayImage: 'Display Image',
  position: 'Position',
  displayPage: 'Display Page',
  adUpdatedSuccessfully: 'Ad updated successfully.',
  adAddedsuccessfully: 'Ad added successfully.',
  adDeletedSuccessfully: 'Ad deleted successfully.',
  notifications: 'Notifications',
  addNotification: 'Add Notification',
  standard: 'Standard',
  custom: 'Custom',
  frequency: 'Frequency',
  title: 'Title',
  addStandardNotification: 'Add Standard Notification',
  addCustomNotification: 'Add Custom Notification',
  editStandardNotification: 'Edit Standard Notification',
  editCustomNotification: 'Edit Custom Notification',
  createOneTime: 'Create one time',
  createSchedule: 'Create schedule',
  thisEvent: 'This event',
  thisAndFollowingEvents: 'This and following events',
  viewStandardNotification: 'View Standard Notification',
  viewCustomNotification: 'View Custom Notification',
  notificationUpdatedSuccessfully: 'Notification updated successfully.',
  notificationAddedSuccessfully: 'Notification added successfully.',
  notificationDeletedSuccessfully: 'Notification deleted successfully.',
  description: 'Description',
  coupons: 'Coupons',
  managers: 'Managers',
  owners: 'Owners',
  myAccount: 'My Account',
  logout: 'Logout',
  editProfile: 'Edit Profile',
  changePassword: 'Change Password',
  role: 'Role',
  saveChanges: 'Save Changes',
  maximumFileSizeAllowed10Mb: 'Maximum file size allowed 10Mb.',
  managedBy: 'Managed By',
  profileUpdatedSuccessfully: 'Profile updated successfully.',
  passwordHasBeenChangedSuccessfully: 'Password has been changed successfully.',
  back: 'Back',
  createCouponCategory: 'Create Coupon Category',
  addNew: 'Add New',
  formDataDeletedSuccessfully: 'Form data deleted successfully.',
  categoryDeletedSuccessfully: 'Category deleted successfully.',
  dataUpdatedSuccessfully: 'Data updated successfully.',
  dataAddedSuccessfully: 'Data added successfully.',
  addStandardCoupon: 'Add Standard Coupon',
  editStandardCoupon: 'Edit Standard Coupon',
  addCustomCoupon: 'Add Custom Coupon',
  editCustomCoupon: 'Edit Custom Coupon',
  deleteLink: 'Delete Link',
  addLink: 'Add Link',
  basicDetails: 'Basic Details',
  viewAll: 'View All',
  documentDetails: 'Document Details',
  add: 'Add',
  addNewTag: 'Add New Tag',
  addImage: 'Add Image',
  addDocument: 'Add Document',
  maximumFileSizeAllowed50Mb: 'Maximum file size allowed 50Mb.',
  tagDetails: 'Tag Details',
  addTag: 'Add Tag',
  viewStandardCoupon: 'View Standard Coupon',
  viewCustomCoupon: 'View Custom Coupon',
  logInToYourCompanyAdminArea: 'Log in to your company admin area',
  newHere: 'New here?',
  createACompany: 'Create a company',
  forgotPassword: 'Forgot Password?',
  login: 'Login',
  allRightsReserved: 'All Rights Reserved.',
  welcomeHomeOnline: 'WELCOME HOME ONLINE',
  termsAndConditions: 'Terms & Conditions',
  privacySettings: 'Privacy Settings',
  createYourCompanyNow: 'Create your company now!',
  alreadyHaveACompany: 'Already have a company?',
  iAgreeToThe: 'I agree to the',
  signUp: 'Sign Up',
  logIn: 'Log In',
  anEmailHasBeenSentSuccessfully: 'An email has been sent successfully.',
  resetPassword: 'Reset Password',
  sendMail: 'Send Mail',
  reSendEmail: 'Re-send Email',
  passwordHasBeenResetSuccessfully: 'Password has been reset successfully.',
  thankyouForSignUp: 'Thank you for sign up',
  ifYouDontFindEmailInInboxThenCheckJunkSpam:
    "Note: (If you don't find an email in inbox then check junk/spam)",
  pleaseCheckYourEmailVerifyAccount:
    'Please check your email to verify account !',
  addPropertyInventory: 'Add Property Inventory',
  editPropertyInventory: 'Edit Property Inventory',
  viewPropertyInventory: 'View Property Inventory',
  addConstructionProducts: 'Add Construction Products',
  editConstructionProducts: 'Edit Construction Products',
  viewBuildingProducts: 'View Construction Products',
  addDocuments: 'Add Documents',
  editDocuments: 'Edit Documents',
  viewDocuments: 'View Documents',
  addSmartHomeDevices: 'Add Smart Home Devices',
  editSmartHomeDevices: 'Edit Smart Home Devices',
  viewSmartDevice: 'View Smart Device',
  addSecureFolder: 'Add Secure Folder',
  editSecureFolder: 'Edit Secure Folder',
  viewSecureFolder: 'View Secure Folder',
  addContact: 'Add Contact',
  editContact: 'Edit Contact',
  note: 'Note',
  viewContactDetail: 'View Contact Detail',
  contactNumber: 'Contact Number',
  companyEmail: 'Contact Email',
  tagDetail: 'Tag Detail',
  links: 'Links',
  viewImages: 'View Images',
  viewImage: 'View Image',
  viewDocument: 'View Document',
  folderAddedSuccessfully: 'Folder added successfully.',
  folderDeletedSuccessfully: 'Folder deleted successfully.',
  allTemplatePropertiesAppliedSuccessfully:
    'All template properties applied successfully.',
  addNewFolder: 'Add New Folder',
  coupon: 'Coupon',
  addHousesExistingFolder: 'Add Houses in Existing Folder',
  addFolder: 'Add Folder',
  editFolder: 'Edit Folder',
  updateFolder: 'Update Folder',
  next: 'Next',
  addHouses: 'Add Houses',
  updateHouses: 'Update Houses',
  previous: 'Previous',
  houseLocation: 'House Location',
  ownerDetail: 'Owner Detail',
  companyDetail: 'Company Detail',
  viewPastOwnerDetails: 'View Past Owner Details',
  managerDetail: 'Manager Detail',
  addDetailsForTemplate: 'Add details for template',
  addDetailsForHouse: 'Add details for house',
  createNewCategory: 'Create New Category',
  pastHomeOwnerDetail: 'Past Home Owner Detail',
  folderName: 'Folder Name',
  houseCount: 'House Count',
  addStandardAdvertisement: 'Add Standard Advertisement',
  editStandardAdvertisement: 'Edit Standard Advertisement',
  addCustomAdvertisement: 'Add Custom Advertisement',
  editCustomAdvertisement: 'Edit Custom Advertisement',
  viewCustomAdvertisement: 'View Custom Advertisement',
  viewStandardAdvertisement: 'View Standard Advertisement',
};

export default en;
