import { Action } from 'interface';
import React from 'react';
import { IUserData } from '../interface/interface';
import * as actionTypes from 'store/action-types';

interface UserState {
  userData: IUserData;
  auth: boolean;
}
const initialState: UserState = {
  userData: {} as IUserData,
  auth: false,
};

const reducer = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        auth: false,
      };
    case actionTypes.AUTH_LOGIN:
      return {
        ...state,
        auth: true,
      };
    default:
      return state;
  }
};

export default reducer;
