import { combineReducers } from 'redux';
import Filter from 'features/LandingPage/store/landingpage.reducer';
import userDataReducer from 'features/authentication/store/reducer';
import { Action, State } from 'interface';
import notificationReducer from 'features/notificationPage/store/notification.reducer';

const appReducer = combineReducers({
  landingPageState: Filter,
  userData: userDataReducer,
  notificationState: notificationReducer,
});
const rootReducer = (state: State | undefined, action: Action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
