import React, { CSSProperties, useState } from 'react';
import DropDownArrow from 'assets/images/DropDownArrow.png';
import Select from 'react-select';
import { IDropDownOptions } from './constant';

interface Props {
  options: IDropDownOptions[];
  placeholder: string;
  handleChange: (selectedOptions: any) => void;
  value?: IDropDownOptions;
  className?: string;
}

const CustomDropDown: React.FC<Props> = (props) => {
  const { value } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const customStyle: object = {
    option: (base: CSSProperties, state: any) => ({
      ...base,
      maxWidth: '582px',
      borderBottom: '1px solid #e7e7e7',
      cursor: 'pointer',
      color: '#444a51',
      padding: '20px',
      backgroundColor: state.isSelected ? '#e7e7e7' : '',
      ':active': {
        backgroundColor: '#e7e7e7',
      },
      ':hover': {
        backgroundColor: '#e7e7e7',
      },
      ':focus': {
        backgroundColor: '#e7e7e7',
        outline: 0,
      },
      textTransform: 'capitalize',
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      maxWidth: '582px',
      zIndex: 99,
    }),
    menuList: (base: CSSProperties) => ({
      ...base,
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      cursor: 'pointer',
    }),
    input: (base: CSSProperties) => ({
      ...base,
      cursor: 'pointer',
    }),
    placeholder: (base: CSSProperties | any) => ({
      ...base,
      color: '#969696',
      fontWeight: 400,
      fontSize: '0.9375rem',
      innerHTML: 'Select Template',
      textTransform: 'capitalize',
    }),
    indicatorsContainer: (base: CSSProperties) => ({
      ...base,
      svg: {
        zIndex: -1,
        display: 'none',
      },
      backgroundImage: ` url(${DropDownArrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '17px 12px',
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      color: '#666666',
      padding: '0px',
      // border: "1px solid #c4c4c4"
    }),
    indicatorSeparator: (base: CSSProperties) => ({
      ...base,
      display: 'none',
    }),
    control: () => ({
      // none of react-selects styles are passed to <View />
      display: 'flex',
      maxWidth: '582px',
      // width: '582px',
      height: '48px !important',
      overflow: 'none',
      border: '1px solid #c4c4c4',
      padding: '0px 18px !important',
      // marginTop: '5px',
      backgroundColor: '#ffffff',
      color: '#444a51',
      fontSize: '1rem',
      borderRadius: '10px',
      cursor: 'pointer',
      fontWeight: '400',
      lineHeight: '16px',
      div: {
        overflowX: 'hidden',
        overflowY: 'initial',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
    }),
    singleValue: (base: CSSProperties, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...base, opacity: opacity, transition: transition };
    },
  };
  return (
    <Select
      options={props.options}
      onChange={(selectedOptions: any) => {
        props.handleChange(selectedOptions);
        setSelectedValue(selectedOptions);
      }}
      className={`form-dropdown--control ${props.className}`}
      styles={customStyle}
      isSearchable={false}
      value={value || selectedValue}
      placeholder={
        <div className="custom-placeholder font--size__16 font--weight__400 line--height__22 text--grey">
          {props.placeholder}
        </div>
      }
    />
  );
};

export default CustomDropDown;
