import { createAction } from 'features/util/utility';
import { State } from 'interface';
import React from 'react';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actionTypes from 'store/action-types';

export const DiscardModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const discardModal = useSelector(
    (state: State) => state.landingPageState.discardModalType
  );

  return (
    <div className="pop-up">
      <div className="pop-up-detail">
        <div className="model-view flexjustify-content--center align-items--center flex__column">
          <Modal
            visible={true}
            width="500"
            height="250"
            effect="fadeInUp"
            onClickAway={() => {}}
          >
            <h1 className="line--height__30px font--size__22 font--weight__bold dark--blue-color">
              Are you sure to discard this current data?
            </h1>
            <div className="button mt--35">
              <button
                onClick={() => dispatch(createAction(actionTypes.CLOSE_MODAL))}
              >
                Continue
              </button>
              <button
                onClick={() => {
                  dispatch(createAction(actionTypes.CLOSE_MODAL));
                  dispatch(createAction(actionTypes.ADD_DISCARD_MODAL, ''));
                  history.push(discardModal);
                }}
                className="delete-data"
              >
                Discard
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
