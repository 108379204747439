export interface UserProps {
  firstName: string;
  lastName: string;
  avatar: null;
  role?: string;
  status?: string;
}

export enum HeaderModalState {
  Discard = 'discard',
}
