import React, { useEffect, useState } from 'react';
import SideBar from 'features/LandingPage/component/sidebar';
import SearchBarHeader from 'features/LandingPage/component/searchBarHeader';
import { useSelector } from 'react-redux';
import { State } from 'interface';

const Layout = (props: any) => {
  const userData = useSelector((state: State) => state.userData.userData);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(false);
  }, []);

  const openSideBar = () => {
    setOpen(!open);
  };
  return (
    <>
      {
        <div className="main-container">
          <SideBar
            toggle={open}
            openSideBar={openSideBar}
            userRole={userData.role}
          />

          <div
            className="main-wrapper"
            style={{
              width: !open ? 'calc(100% - 68px)' : 'calc(100% - 230px)',
              // transition: 'ease-in 0.5s',
            }}
          >
            <SearchBarHeader open={open} userData={userData} />
            <div className="dashboard">{props.children}</div>
          </div>
          {/* {<div className="secondary-side--wrapper" onClick={openSideBar} style={{ display: open ? 'none' : 'block' }}>
          <div className="">
            <CloseIcon className={`position--absolute pointer`} />
          </div>
        </div>} */}
        </div>
      }
    </>
  );
};

export default Layout;
