import React from 'react';
import * as Sentry from '@sentry/browser';

interface OwnProps {
  children?: any;
}

class ErrorBoundary extends React.Component<OwnProps> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error | null) {
    return { error };
  }

  componentDidMount() {
    // window.onerror = this.logError;
  }

  componentDidCatch(error: Error | null, info: any) {
    this.logError(error);
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return <p className="black--color">System is updating, Please hold on</p>;
    }
    return this.props.children;
  }

  logError(args: Error | null) {
    console.log(args);
  }
}

export default ErrorBoundary;
