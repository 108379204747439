import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import {
  DeleteIcon,
  EditIcon,
  FilterIcon,
  HelpIcon,
  PasswordEyeIcon,
  SearchIcon,
} from 'shared/components/icons';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeadingCell,
  TableRow,
} from 'features/UserList/component/table';
import { adsTableHead } from './adsData';
import { TableEmptyContainer } from 'shared/components/tableEmptyContainer/tableEmptyContainer';
import ToggleSwitch from 'features/GroupsPage/container/toggleSwitch';
import {
  IPagindationDetail,
  IsortTableProps,
  sortIconMapper,
} from 'shared/components/constant';
import { convertDateToIsoFormat } from 'features/util/utility';
import { PaginationDataProps } from 'features/UserList/container/constant';
import {
  ActionTypeEnum,
  CategoryTypeEnum,
  CurrentRoleEnum,
} from 'features/categoryPage/component/constant/constant';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';
import { ReactPagindationFile } from 'shared/components/paginationFile/paginationFile';
import { StandardCustomData } from 'features/notificationPage/component/standardCustomFile';
import { useSelector } from 'react-redux';
import { State } from 'interface';
import { getAuthData } from 'services/auth.service';
import { notify } from 'shared/components/notification/notification';

interface Props {
  handleModelState: (action: string) => void;
  accessAdsData: any;
  handleGetAdsData: (id: string, type: string) => void;
  pagination: IPagindationDetail;
  handlePageClick: (e: any) => void;
  datas: PaginationDataProps;
  setSerialNumber: number;
  isLoading: boolean;
  handleAddStatusPopUpData: (id: string, ispublished: boolean) => void;
  handleDeletePopUpData: (id: string) => void;
  handlePopUpData: () => void;
  resetFilter: boolean;
  handleResetFilter: () => void;
  handleSortData: (value: any) => void;
  sort: IsortTableProps;
  handleSearch: (value: string) => void;
  searchData: string;
  handleInfoPage: (action: boolean) => void;
  advertisementType: string;
  handleFilterClick: (action: string) => void;
}

const AdsDashboard: React.FC<Props> = (props) => {
  const {
    handleInfoPage,
    setSerialNumber,
    handleGetAdsData,
    handleAddStatusPopUpData,
    handleDeletePopUpData,
    accessAdsData,
    searchData,
    handleSearch,
    resetFilter,
    handlePopUpData,
    handleResetFilter,
    handleModelState,
    handlePageClick,
    handleSortData,
    sort,
    isLoading,
    datas,
    pagination,
    advertisementType,
    handleFilterClick,
  } = props;

  const {
    view,
    edit,
    deleteData,
    noDataFound,
    clearFilter,
    help,
    addAd,
    ads,
    ad,
    pleaseSelectCompanyFromDropdown,
  } = localizationConstants;
  const { t } = useTranslation();
  const filter = useSelector((state: State) => state.landingPageState.filter);
  const userData = getAuthData();
  const selectCompanyDropdown =
    !filter.value &&
    userData.role === CurrentRoleEnum.SuperAdminRole &&
    advertisementType === CategoryTypeEnum.Custom;

  const handleAdsForm = () => {
    if (selectCompanyDropdown) {
      notify(t(pleaseSelectCompanyFromDropdown), 'error');
      return;
    }
    handleModelState(ActionTypeEnum.AdForm);
    handleInfoPage(true);
  };

  const handleSearchData = (e: any) => {
    if (selectCompanyDropdown) {
      notify(t(pleaseSelectCompanyFromDropdown), 'error');
      return;
    }
    handleSearch(e.target.value);
  };
  const handleFilterPopUpClick = () => {
    if (selectCompanyDropdown) {
      notify(t(pleaseSelectCompanyFromDropdown), 'error');
      return;
    }
    handlePopUpData();
  };

  const renderTableRow = (adsData: any, index: number) => {
    const { name, id, startDate, endDate, ispublished } = adsData;

    const tableData = [
      {
        className: 'user-table--serialno font--weight__500',
        key: 'setId',
        data: setSerialNumber + index,
        component: '',
      },
      {
        className: 'user-table--template__name font--weight__400',
        key: 'setName',
        data: '',
        component: (
          <div
            className="name-txt"
            onClick={() => {
              handleGetAdsData(id, ActionTypeEnum.ViewForm);
            }}
            title={`${t(view)}`}
          >
            <h2 className="font--size__18 line--height__22 font--weight__400">
              {name}
            </h2>
          </div>
        ),
      },
      {
        className: 'user-table--serialno font--weight__400',
        key: 'startDate',
        data: convertDateToIsoFormat(startDate, true),
        component: '',
      },
      {
        className: 'user-table--serialno font--weight__400',
        key: 'endDate',
        data: convertDateToIsoFormat(endDate, true),
      },
      {
        className: 'user-table--status',
        key: 'status',
        data: '',
        component: (
          <div className="">
            <ToggleSwitch
              handlePostStatusData={() =>
                handleAddStatusPopUpData(id, ispublished)
              }
              checked={ispublished}
            />
          </div>
        ),
      },
      {
        className: 'user-table--action font--weight__400',
        key: 'action',
        data: '',
        component: (
          <div className="action-list">
            <div className="action-data flex">
              <button
                type="button"
                className="bg--white no--border"
                onClick={() => handleGetAdsData(id, ActionTypeEnum.ViewForm)}
                title={`${t(view)}`}
              >
                <PasswordEyeIcon className="add-data-logo mr--36 grey--icon" />
              </button>
              <button
                className="bg--white no--border"
                onClick={() => handleGetAdsData(id, ActionTypeEnum.EditForm)}
                title={`${t(edit)}`}
              >
                <EditIcon className="add-data-logo mr--36" />
              </button>
              <button
                className="bg--white no--border"
                onClick={() => handleDeletePopUpData(id)}
                title={`${t(deleteData)}`}
              >
                <DeleteIcon className="delete-data-logo" />
              </button>
            </div>
          </div>
        ),
      },
    ];

    const tableRows = () =>
      tableData.map((data: any) => (
        <TableCell
          className={`font--size__18 dark--black-color line--height__22 ${data.className}`}
          key={data.key}
        >
          {data.component ? data.component : data.data}
        </TableCell>
      ));
    return (
      <TableRow className="user-action width " key={`tableRow ${index}`}>
        {tableRows()}
      </TableRow>
    );
  };

  return (
    <>
      <div className="user-list--section flex justify-content--center align-items--center dashboard-container">
        <div className="user-list--container flex flex--column justify-content--center align-items--center width">
          <StandardCustomData
            notification={advertisementType}
            handleFilterClick={handleFilterClick}
          />
          <div className="user-list--wrapper width">
            <div className="user-history--header flex justify-content--between align-items--center">
              <div className="user-title flex align-items--center width--10">
                <h4 className="font--size__24 dark--black-color font--weight__bold line--height__29px flex align-items--center">
                  <Translate text={accessAdsData?.length > 1 ? ads : ad} />
                </h4>
              </div>
              <div className="width--90 justify-content--end flex align-items--center">
                <div className="search-field width position--relative flex align-items--center ml--30">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="search-input width "
                    placeholder="Search"
                    value={searchData}
                    onChange={(e) => handleSearchData(e)}
                  />
                  <span className="search-icon position--absolute">
                    <SearchIcon className="search-logo" />
                  </span>
                </div>
                <div className="addgroup-btn--wrapper flex justify-content--center align-items--center">
                  {resetFilter && (
                    <div className="reset-btn--wrapper width">
                      <p
                        className="text--underline dark--blue-color font--size__18 pointer font--weight__500 width"
                        onClick={handleResetFilter}
                        title={`${t(clearFilter)}`}
                      >
                        <Translate text={clearFilter} />
                      </p>
                    </div>
                  )}
                  <button
                    className="filter-icon"
                    onClick={handleFilterPopUpClick}
                  >
                    <FilterIcon />
                  </button>
                  <div className="flex align-items--center">
                    <button
                      type="button"
                      className="add-group--btn dark--blue-color font--size__18 pointer font--weight__500"
                      onClick={handleAdsForm}
                    >
                      <Translate text={addAd} />
                    </button>
                    <button
                      className="help--button bg--transparent no--border ml--10"
                      onClick={() => handleInfoPage(true)}
                      title={`${t(help)}`}
                    >
                      <HelpIcon className="help--icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="user-table">
              <Table>
                <TableHead className="user-table--header text--left ">
                  <TableRow className="width">
                    {adsTableHead.map((header, index) => (
                      <TableHeadingCell
                        key={`header ${index}`}
                        className={` ${header.classname}`}
                      >
                        <div className="flex align-items--center">
                          {header.id}
                          {header.sort && (
                            <button
                              className="bg--transparent no--border"
                              onClick={() => handleSortData(header.key)}
                            >
                              {
                                sortIconMapper[
                                  header.key === sort.sort
                                    ? sort.sortType
                                    : 'default'
                                ]
                              }
                            </button>
                          )}
                        </div>
                      </TableHeadingCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="user-table--body">
                  {!isLoading && accessAdsData.length === 0 && (
                    <>
                      <tr>
                        <td colSpan={adsTableHead.length}>
                          <h2 className="dark--blue-color font--weight__500 text--center">
                            <Translate text={noDataFound} />
                          </h2>
                        </td>
                      </tr>
                    </>
                  )}
                  {isLoading && (
                    <TableEmptyContainer colSpan={adsTableHead.length} />
                  )}
                  {accessAdsData?.length > 0 &&
                    !isLoading &&
                    accessAdsData.map((adsData: any, index: number) =>
                      renderTableRow(adsData, index)
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          {accessAdsData?.length > 0 && (
            <ReactPagindationFile
              datas={datas}
              handlePageClick={handlePageClick}
              pagination={pagination}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdsDashboard;
