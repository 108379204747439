import React from 'react';
import MultiSelectDropDown from 'features/UserList/container/multiSelectDropDown';
import ErrorMessageHandler from 'shared/components/errorMessage/erroMessage';
import Translate from 'shared/components/translate/translate';
import localizationConstants from 'features/util/translation/constant';

interface Props {
  handleMultiChangeForHouse?: (e: any) => void;
  handleMultiChangeForGroup?: (e: any) => void;
  setFieldValue: (
    name: string,
    options: { label: string; value: string }
  ) => void;
  houseArray: string[];
  groupArray: string[];
  groupDropDownList?: string[];
  houseDropDownList?: string[];
  houseName: string;
  groupName: string;
}

interface MultiDropDownProps {
  changeData: any;
  selectedValue: any;
  options: any;
  placeholder: string;
  name: string;
  localizationText: string;
  classname: string;
}

export const MultiSelectHouseFolderFile: React.FC<Props> = (props) => {
  const { houses, folders } = localizationConstants;
  const dropDownArray = [
    {
      changeData: () => props.handleMultiChangeForHouse,
      selectedValue: props.houseArray,
      options: props.houseDropDownList,
      placeholder: 'Select House',
      name: props.houseName,
      localizationText: houses,
      classname: 'mr--20',
    },
    {
      changeData: () => props.handleMultiChangeForGroup,
      selectedValue: props.groupArray,
      options: props.groupDropDownList,
      placeholder: 'Select Folder',
      name: props.groupName,
      localizationText: folders,
      classname: '',
    },
  ];
  return (
    <div className="form-container mt--20 flex mb--20">
      {dropDownArray.map((data: MultiDropDownProps, index: number) => (
        <div className="mr--20 width" key={index}>
          <h2 className="dark--black-color font--weight__500 font--size__18 line--height__22 mb--10">
            <Translate text={data.localizationText} />
          </h2>
          <MultiSelectDropDown
            handleMultiChange={data.changeData}
            selectedValue={data.selectedValue}
            options={data.options}
            placeholder={data.placeholder}
            handleChange={(selectedOptions: any) => {
              props.setFieldValue(data.name, selectedOptions);
            }}
          />
          <ErrorMessageHandler name={data.name} />
        </div>
      ))}
    </div>
  );
};
