import React, { useState } from 'react';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { CloseLogo } from 'shared/components/icons';

interface Props {
  className?: string;
  modelText?: string;
  button?: string;
  handlePreviewAdsPopUpData: (type: boolean) => void;
  previewUrl: any;
}

const AdsImagePopUp: React.FC<Props> = (props) => {
  const { previewUrl, handlePreviewAdsPopUpData } = props;

  const [visible, setVisible] = useState(true);
  const handleCloseModel = () => {
    setVisible(true);
  };

  return (
    <div className="pop-up">
      <div className="pop-up-detail">
        <div className="model-view flex justify-content--center align-items--center flex__column">
          <Modal
            visible={visible}
            width="100%"
            height="100%"
            effect="fadeInUp"
            onClickAway={handleCloseModel}
          >
            <div className="preview-data">
              <div className="preview-wrapper">
                <div
                  className="preview-icon pointer flex justify-content--center align-items--center"
                  onClick={() => handlePreviewAdsPopUpData(false)}
                >
                  <CloseLogo className="preview-close--icon " />
                </div>
              </div>

              <div className="flex align-items--center justify-content--center height width ">
                {
                  <div style={{ width: '80%', height: '80%' }}>
                    <img
                      src={previewUrl}
                      alt=""
                      className="width height-fill--available"
                    />
                  </div>
                }
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdsImagePopUp;
