import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikValues, isEmptyArray } from 'formik';
import CustomDropDown from 'shared/components/customDropDown';
import Input from 'shared/components/inputType';
import * as Yup from 'yup';
import UploadFileType from 'shared/components/uploadFileType';
import { BreadCrumbs } from 'shared/components/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { isDiscardModal } from 'features/util/utility';
import { addDiscardModal } from 'store/actionFolder/actionFolder';
import ErrorMessageHandler from 'shared/components/errorMessage/erroMessage';
import DatePickerFile from 'shared/components/datePicker/datePickerFile';
import {
  ActionTypeEnum,
  CategoryTypeEnum,
  StatusEnum,
} from 'features/categoryPage/component/constant/constant';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';
import { MultiSelectHouseFolderFile } from 'features/notificationPage/constant/multiSelectHouseFolder';

interface Props {
  handleModelState: (action: string) => void;
  onSubmit: (values: FormikValues) => void;
  handleStartDate: (value: Date) => void;
  handleEndDate: (value: Date) => void;
  initialValues: any;
  action: string;
  getWebFileName: (event: any, type: string) => void;
  getMobileFileName: (event: any, type: string) => void;
  editAllData: any;
  handleInfoPage: (action: boolean) => void;
  isActionLoading: boolean;
  advertisementType: string;
  handleMultiChangeForHouse: (e: any) => void;
  handleMultiChangeForGroup: (e: any) => void;
  groupDropDownList: string[];
  houseDropDownList: string[];
  loadingData: {
    isLoad: boolean;
    type: string;
  };
}

const AdsForm: React.FC<Props> = (props) => {
  const {
    onSubmit,
    handleInfoPage,
    handleEndDate,
    handleStartDate,
    handleModelState,
    action,
    getWebFileName,
    editAllData,
    initialValues,
    isActionLoading,
    advertisementType,
    handleMultiChangeForGroup,
    handleMultiChangeForHouse,
    groupDropDownList,
    houseDropDownList,
    loadingData,
  } = props;
  const {
    manageAds,
    addAd,
    editAd,
    closeButton,
    submitButton,
    updateButton,
    addStandardAdvertisement,
    editStandardAdvertisement,
    addCustomAdvertisement,
    editCustomAdvertisement,
  } = localizationConstants;
  const dispatch = useDispatch();
  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const dispatchDiscardModal = () => {
    dispatch(addDiscardModal('edited'));
  };

  const submitValues = (values: FormikValues) => {
    onSubmit(values);
    handleInfoPage(false);
  };

  const positonOptions = [
    { value: 'left', label: 'LEFT' },
    { value: 'right', label: 'RIGHT' },
    // { value: 'center', label: 'CENTER' },
    // { value: 'bottom', label: 'BOTTOM' },
    // { value: 'top', label: 'TOP' },
    // { value: 'top-left', label: 'TOP LEFT' },
    // { value: 'top-right', label: 'TOP RIGHT' },
    // { value: 'bottom-right', label: 'BOTTOM RIGHT' },
    // { value: 'bottom-left', label: 'BOTTOM LEFT' },
  ];

  const statusPosition = [
    { value: true, label: 'Active' },
    { value: false, label: 'Deactive' },
  ];

  const displayOptions = [{ value: 'Landing Page', label: 'Landing Page' }];

  const handleBackNavigation = () => {
    handleModelState(ActionTypeEnum.AdDashboard);
    dispatch(addDiscardModal(''));
    handleInfoPage(false);
  };

  return (
    <div className="dashboard-container">
      <div className="group-form__wrapper">
        <div className="category-header flex align-items--center dark--black-color line--height__22">
          <div
            className="template-link text--underline mr--30 width"
            onClick={handleBackNavigation}
          >
            <span className="primary--black pointer font--weight__700 font--size__24">
              <Translate text={manageAds} />
            </span>
          </div>
          <BreadCrumbs className="mr--30" />
          <div className="add-group--header form-container flex justify-content--between align-items--center width">
            {advertisementType === CategoryTypeEnum.Standard && (
              <h4 className="font--size__18 line--height__22 dark--black-color font--weight__500 flex align-items--center">
                <Translate
                  text={
                    action === ActionTypeEnum.EditForm
                      ? editStandardAdvertisement
                      : addStandardAdvertisement
                  }
                />
              </h4>
            )}
            {advertisementType === CategoryTypeEnum.Custom && (
              <h4 className="font--size__18 line--height__22 dark--black-color font--weight__500 flex align-items--center">
                <Translate
                  text={
                    action === ActionTypeEnum.EditForm
                      ? editCustomAdvertisement
                      : addCustomAdvertisement
                  }
                />
              </h4>
            )}
          </div>
        </div>

        <Formik
          initialValues={
            action === ActionTypeEnum.EditForm
              ? initialValues
              : {
                  name: '',
                  position: '',
                  link: '',
                  image: '',
                  ispublished: '',
                  startDate: '',
                  endDate: '',
                  displayPage: '',
                  mobile_image: '',
                  groupId: [],
                  houseId: [],
                }
          }
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={adsValidationSchema}
          onSubmit={(values, { setErrors, resetForm }) => {
            if (
              values.groupId?.length === 0 &&
              values.houseId?.length === 0 &&
              advertisementType === CategoryTypeEnum.Custom
            ) {
              const windowScroll = window.scrollTo(0, 0);
              if (!!isEmptyArray(houseDropDownList && groupDropDownList)) {
                setErrors({
                  houseId: 'Please create house first for your company..',
                  groupId: 'Please create folder first for your company..',
                });
              } else {
                setErrors({
                  groupId: 'Please select folder.',
                  houseId: 'Please select house.',
                });
              }
              return windowScroll;
            } else {
              submitValues(values);
            }
          }}
        >
          {({ values, setFieldValue }) => {
            const houseArray: string[] = [];
            values.houseId?.map((data: any) => houseArray.push(data.value));
            const groupArray: string[] = [];
            values.groupId?.map((data: any) => groupArray.push(data.value));
            const dropDownList = (
              option: any[],
              placeHolder: any,
              name: string
            ) => (
              <>
                <CustomDropDown
                  options={option}
                  placeholder={placeHolder}
                  handleChange={(selectedOptions: any) => {
                    setFieldValue(name, selectedOptions.value);
                  }}
                />
                <ErrorMessageHandler name={name} />
              </>
            );

            return (
              <Form>
                {advertisementType !== CategoryTypeEnum.Standard && (
                  <MultiSelectHouseFolderFile
                    handleMultiChangeForGroup={handleMultiChangeForGroup}
                    handleMultiChangeForHouse={handleMultiChangeForHouse}
                    setFieldValue={setFieldValue}
                    houseArray={houseArray}
                    groupArray={groupArray}
                    groupDropDownList={groupDropDownList}
                    houseDropDownList={houseDropDownList}
                    houseName="houseId"
                    groupName="groupId"
                  />
                )}
                <div className="form-container mt--35 height-60">
                  <Input
                    type="text"
                    name="name"
                    className="mr--32"
                    setFieldValue={setFieldValue}
                    placeholder="Ad title"
                    onBlur={() => {
                      if (isDiscardModal(values)) {
                        dispatchDiscardModal();
                      }
                    }}
                    config={{ name: 'name' }}
                    autoFocus
                  />
                </div>
                <div className="house-form--field form-container flex justify-content--start align-items--center mt--20 ">
                  <div className={`input-data width height-60 mr--20`}>
                    <UploadFileType
                      getFileName={(e: any) => {
                        getWebFileName(e, 'image');
                        setFieldValue('image', e.target.files[0].name);
                      }}
                      fileName={values.image}
                      showText="Web Image"
                    />
                    <ErrorMessageHandler name="image" />
                  </div>

                  <div className={`input-data width height-60`}>
                    <UploadFileType
                      getFileName={(e: any) => {
                        getWebFileName(e, 'mobile_image');
                        setFieldValue('mobile_image', e.target.files[0].name);
                      }}
                      fileName={values.mobile_image}
                      showText="Mobile Image"
                    />
                    <ErrorMessageHandler name="mobile_image" />
                  </div>
                </div>
                <div className="house-form--field form-container flex justify-content--start align-items--center mt--20">
                  <div className="mr--20 width height-60">
                    <DatePickerFile
                      values={values.startDate}
                      handleEndDate={handleStartDate}
                      setFieldValue={setFieldValue}
                      filterPassedTime={filterPassedTime}
                      name="startDate"
                      placeHolder="Start Date and Time"
                      date="startDate"
                    />

                    <ErrorMessageHandler name="startDate" />
                  </div>
                  <div className="width height-60">
                    <DatePickerFile
                      values={values.endDate}
                      handleEndDate={handleEndDate}
                      setFieldValue={setFieldValue}
                      filterPassedTime={filterPassedTime}
                      name="endDate"
                      placeHolder="End Date and Time"
                      date="endDate"
                    />
                    <ErrorMessageHandler name="endDate" />
                  </div>
                </div>
                <div className="form-container mt--20  flex">
                  <div className="width mr--20 height-60">
                    <Input
                      type="text"
                      name="link"
                      className="width"
                      setFieldValue={setFieldValue}
                      placeholder="Advertise Link"
                      onBlur={() => {
                        if (isDiscardModal(values)) {
                          dispatchDiscardModal();
                        }
                      }}
                      config={{ name: 'link' }}
                    />
                  </div>
                  <div className="width height-60">
                    <>
                      {dropDownList(
                        positonOptions,
                        action === ActionTypeEnum.EditForm
                          ? editAllData.position
                          : 'Position',
                        'position'
                      )}
                    </>
                  </div>
                </div>
                <div className="form-container mt--20 flex">
                  <div className="width mr--20 height-60">
                    <>
                      {dropDownList(
                        statusPosition,
                        action === ActionTypeEnum.EditForm
                          ? `${
                              editAllData.ispublished === true
                                ? StatusEnum.Active
                                : StatusEnum.Deactive
                            }`
                          : 'STATUS',
                        'ispublished'
                      )}
                    </>
                  </div>
                  <div className=" width height-60 visibility">
                    <>
                      {dropDownList(
                        displayOptions,
                        action === ActionTypeEnum.EditForm
                          ? editAllData.displayPage
                          : 'Display On',
                        'displayPage'
                      )}
                    </>
                  </div>
                </div>
                <div className="horizontal-lines width" />
                <div className="flex align-items--center">
                  <button
                    type="button"
                    className="cancel-btn font--weight__500 font--size__18"
                    onClick={handleBackNavigation}
                  >
                    <Translate text={closeButton} />
                  </button>
                  <button
                    type="submit"
                    className="submit-btn font--weight__500 font--size__18 dark--blue-color flex justify-content--center align-items--center pl--10 pr--10"
                    disabled={isActionLoading || loadingData.isLoad}
                  >
                    <Translate
                      text={
                        action === ActionTypeEnum.EditForm
                          ? updateButton
                          : submitButton
                      }
                    />
                    {isActionLoading && <div className="login-loader ml--10" />}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AdsForm;

const adsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name should not be empty.').strict(true),
  link: Yup.string()
    .matches(
      /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
      'Enter correct url.'
    )
    .required('Link should not be empty.')
    .strict(true),
  image: Yup.string()
    .required('Image should not be empty. (Upload image of 350 X 600 px.)')
    .strict(true),
  mobile_image: Yup.string()
    .required(
      'Mobile image should not be empty. (Upload image of 580 X 70 px.)'
    )
    .strict(true),
  position: Yup.string().required('Please select one position.').strict(true),
  startDate: Yup.mixed().required('Please select start date.').strict(true),
  endDate: Yup.mixed()
    .required('End Date must be grater then start date.')
    .strict(true),
  ispublished: Yup.boolean()
    .transform((ispublished) => (ispublished === true ? 'ACTIVE' : 'DEACTIVE'))
    .required('Please select one option.')
    .strict(true),
  // displayPage: Yup.string()
  //   .required('Please select one display page.')
  //   .strict(true),
});
