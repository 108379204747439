import React from 'react';
import { AscOrder, DescOrder, SortIcon } from './icons';

export interface IDropDownOptions {
  label: string;
  value: string | number | boolean;
}

export const sortIconMapper: any = {
  default: <SortIcon className="sort-order" />,
  asc: <AscOrder className="desc-order" />,
  desc: <DescOrder className="desc-order" />,
};

export enum RoleBasedActions {
  manager = 'MANAGER',
  user = 'USER',
  builder = 'BUILDER',
  superadmin = 'SUPERADMIN',
}

export interface IPagindationDetail {
  currentPage: number;
  totalPages: number;
  total: number;
  recordPerPage: number;
  nextPage: number;
  remainingCount: number;
}

export interface IsortTableProps {
  sortType: string;
  sort: string;
}
