import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CategoryTypeEnum } from 'features/categoryPage/component/constant/constant';
import Translate from 'shared/components/translate/translate';
import localizationConstants from 'features/util/translation/constant';

interface Props {
  notification: string;
  handleFilterClick: (value: string) => void;
}

export const StandardCustomData: React.FC<Props> = (props) => {
  const {
    standard,
    custom,
    viewCustomNotification,
    viewStandardNotification,
    viewStandardCoupon,
    viewCustomCoupon,
  } = localizationConstants;
  const history = useHistory();
  const historyState = history.location.pathname.split('/')[1];
  const { t } = useTranslation();
  return (
    <div className=" flex justify-content--start align-items--center width ml--40">
      <button
        className={`tab-btn ${
          props.notification === CategoryTypeEnum.Standard
            ? 'tab-btn-active'
            : ''
        }`}
        onClick={() => {
          props.handleFilterClick(CategoryTypeEnum.Standard);
        }}
        title={`${t(
          historyState === 'coupon'
            ? viewStandardCoupon
            : viewStandardNotification
        )}`}
      >
        <Translate text={standard} />
      </button>
      <button
        className={`tab-btn ${
          props.notification === CategoryTypeEnum.Custom ? 'tab-btn-active' : ''
        }`}
        onClick={() => {
          props.handleFilterClick(CategoryTypeEnum.Custom);
        }}
        title={`${t(
          historyState === 'coupon' ? viewCustomCoupon : viewCustomNotification
        )}`}
      >
        <Translate text={custom} />
      </button>
    </div>
  );
};
