import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import 'assets/styles/index.scss';
import Spinner from 'shared/components/spinner/spinner';
import Layout from 'shared/components/layout';
import PublicLayout from 'features/authentication/component/publicLayout';
import { useSelector } from 'react-redux';
import { State } from 'interface';
import { checkLogin } from 'services/auth.service';
import TermsCondition from 'features/authentication/component/termsCondition';
import PrivacyPolicy from 'features/authentication/component/privacyPolicy';
import TermsAndPrivacy from 'features/authentication/component/termsAndPrivacy';
import AdvertisementFormHandling from 'features/advertisement/container/AdsFormHandling';

const AdminPanel = lazy(
  () => import('features/LandingPage/component/adminPanel')
);
const Login = lazy(() => import('features/authentication/component/login'));
const AddGroupFormHandling = lazy(
  () => import('features/GroupsPage/container/addGroupFormHandling')
);
const AddGroupFormHandlingForHouse = lazy(
  () => import('features/GroupsPage/container/addGroupFormHandling')
);
const TemplateFormHandling = lazy(
  () => import('features/TemplatePage/container/templateFormHandling')
);
const UserFormHandling = lazy(
  () => import('features/UserList/container/userFormHandling')
);
const ManagerFormHandling = lazy(
  () =>
    import('features/UserList/managerComponent/component/managerFormHandling')
);
const OwnerFormHandling = lazy(
  () =>
    import('features/UserList/homeOwnerComponent/component/ownerFormHandling')
);
const HouseFormHandling = lazy(
  () => import('features/HousePage/container/houseFormHandling')
);
const ForgotPassword = lazy(
  () => import('features/authentication/component/forgotPassword')
);
const SignUp = lazy(() => import('features/authentication/signUp'));
const EditProfile = lazy(
  () => import('features/authentication/component/editProfile')
);
const ChangePassword = lazy(
  () => import('features/authentication/component/resetPassword')
);
const AdsFormHandling = lazy(
  () => import('features/adsPage/container/AdsFormHandling')
);
// const AdvertisementFormHandling = lazy(
//   () => import('features/advertisement/container/AdsFormHandling')
// );
const SmartDeviceFormHandling = lazy(
  () => import('features/categoryPage/container/smartDeviceFormHandling')
);
const HomeInventoryFormHandling = lazy(
  () => import('features/categoryPage/container/homeInventoryFormHandling')
);
const CouponFormHandling = lazy(
  () => import('features/categoryPage/container/couponFormHandling')
);
const DocumentFormHandling = lazy(
  () => import('features/categoryPage/container/documentFormHandling')
);
const BuilderFormHandling = lazy(
  () => import('features/categoryPage/container/builderFormHandling')
);
const ContactFormHandling = lazy(
  () => import('features/categoryPage/container/contactFormHandling')
);
const SecureDataFormHandling = lazy(
  () => import('features/categoryPage/container/secureDataFormHandling')
);
const ActivationEmail = lazy(
  () => import('features/authentication/component/activationEmail')
);
const ResetPassword = lazy(
  () => import('features/authentication/component/resetPasswordLink')
);
const ResendEmail = lazy(
  () => import('features/authentication/component/resendEmail')
);
const SuccessEmail = lazy(
  () => import('features/authentication/component/successEmail')
);
const NotificationFormHandling = lazy(
  () => import('features/notificationPage/container/notificationFormHandling')
);
const customNotificationFormHandling = lazy(
  () =>
    import(
      'features/TemplatePage/customNotification/customNotificationFormHandling'
    )
);

const App = () => {
  let isLoggedIn = checkLogin();
  const auth = useSelector((state: State) => state.userData.auth);

  useEffect(() => {
    isLoggedIn = checkLogin();
  }, [auth]);

  if (isLoggedIn) {
    return (
      <Layout>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {/* <Route exact path='/' component={AdminPanel} /> */}
            <Route
              path="/house/home-inventory/:catId/:formId/:id"
              component={HomeInventoryFormHandling}
              exact
            />
            <Route
              path="/house/smart-device/:catId/:formId/:id"
              component={SmartDeviceFormHandling}
              exact
            />

            <Route
              path="/house/building-product/:catId/:formId/:id"
              component={BuilderFormHandling}
              exact
            />
            <Route
              path="/house/document/:catId/:formId/:id"
              component={DocumentFormHandling}
              exact
            />
            <Route
              path="/house/contact/:catId/:formId/:id"
              component={ContactFormHandling}
              exact
            />
            <Route
              path="/house/secure-folder/:catId/:formId/:id"
              component={SecureDataFormHandling}
              exact
            />
            <Route path="/dashboard" component={AdminPanel} />
            <Route path="/user" component={UserFormHandling} />
            <Route path="/manager" component={ManagerFormHandling} />
            <Route path="/owner" component={OwnerFormHandling} />
            <Route exact path="/group" component={AddGroupFormHandling} />
            <Route
              path="/house/:type"
              component={AddGroupFormHandlingForHouse}
            />
            <Route path="/house" component={HouseFormHandling} />
            <Route exact path="/template" component={TemplateFormHandling} />
            <Route path="/manage-ads" component={AdsFormHandling} />
            <Route
              path="/advertisement"
              component={AdvertisementFormHandling}
            />
            <Route path="/edit-profile" component={EditProfile} />

            <Route
              path="/coupon/home-inventory/:catId/:formId/:id"
              component={CouponFormHandling}
            />
            <Route
              path="/customCoupon/home-inventory/:id"
              component={HomeInventoryFormHandling}
            />
            {/* <Route
              path="/coupon/building-product/:id"
              component={BuilderFormHandling}
            />
            <Route
              path="/coupon/document/:id"
              component={DocumentFormHandling}
            />
            <Route path="/coupon/contact/:id" component={ContactFormHandling} />
            <Route
              path="/coupon/secure-folder/:id"
              component={SecureDataFormHandling}
            />
            <Route
              path="/coupon/smart-device/:id"
              component={SmartDeviceFormHandling}
            /> */}
            <Route
              path="/customCoupon/building-product/:id"
              component={BuilderFormHandling}
            />
            <Route
              path="/customCoupon/document/:id"
              component={DocumentFormHandling}
            />
            <Route
              path="/customCoupon/contact/:id"
              component={ContactFormHandling}
            />
            <Route
              path="/customCoupon/secure-folder/:id"
              component={SecureDataFormHandling}
            />
            <Route
              path="/customCoupon/smart-device/:id"
              component={SmartDeviceFormHandling}
            />
            <Route path="/notification" component={NotificationFormHandling} />
            {/* <Route
              path="/notification/custom"
              component={NotificationFormHandling}
            /> */}
            <Route path="/change-password" component={ChangePassword} />
            <Route
              path="/group/smart-device/:id/:templateId"
              component={SmartDeviceFormHandling}
            />

            <Route
              path="/group/home-inventory/:id"
              component={HomeInventoryFormHandling}
            />
            <Route
              path="/group/building-product/:id"
              component={BuilderFormHandling}
            />
            <Route
              path="/group/document/:id"
              component={DocumentFormHandling}
            />
            <Route path="/group/contact/:id" component={ContactFormHandling} />
            <Route
              path="/group/secure-folder/:id"
              component={SecureDataFormHandling}
            />

            <Route
              path="/template/smart-device/:id"
              component={SmartDeviceFormHandling}
            />
            <Route
              path="/template/home-inventory/:id"
              component={HomeInventoryFormHandling}
            />
            <Route
              path="/template/building-product/:id"
              component={BuilderFormHandling}
            />
            <Route
              path="/template/document/:id"
              component={DocumentFormHandling}
            />
            <Route
              path="/template/contact/:id"
              component={ContactFormHandling}
            />
            <Route
              path="/template/secure-folder/:id"
              component={SecureDataFormHandling}
            />
            <Route
              path="/templatePreview/smart-device:id"
              component={SmartDeviceFormHandling}
            />
            <Route
              path="/templatePreview/home-inventory:id"
              component={HomeInventoryFormHandling}
            />
            <Route
              path="/templatePreview/building-product:id"
              component={BuilderFormHandling}
            />
            <Route
              path="/templatePreview/document:id"
              component={DocumentFormHandling}
            />
            <Route
              path="/templatePreview/contact:id"
              component={ContactFormHandling}
            />
            <Route
              path="/templatePreview/secure-folder:id"
              component={SecureDataFormHandling}
            />
            <Route
              path="/template/notification"
              component={customNotificationFormHandling}
            />

            {/* <Route path="/notification" component={NotificationPopUp} /> */}
            {/* <Redirect from="/*" to="/dashboard" /> */}
            {/* <Route path='/group/calendar' component={} /> */}
            <Redirect to="/dashboard" />
          </Switch>
        </Suspense>
      </Layout>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={'/terms-condition'} component={TermsCondition} />
        <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
        <Route exact path={'/static/:id'} component={TermsAndPrivacy} />
        <PublicLayout>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {/* <Route exact path='/' component={Login} /> */}
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/verify-email" component={SuccessEmail} />
              <Route
                exact
                path="/activation/:token"
                component={ActivationEmail}
              />
              <Route
                exact
                path="/reset-password/:token"
                component={ResetPassword}
              />
              <Route exact path="/resend" component={ResendEmail} />
              <Route exact path="/login" component={Login} />
              <Redirect to="/login" />
            </Switch>
          </Suspense>
        </PublicLayout>
      </Switch>
    </Suspense>
  );
};

export default App;
