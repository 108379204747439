import React from 'react';
import Switch from 'react-switch';

interface Props {
  handlePostStatusData: () => void;
  checked?: any;
}

const ToggleSwitch: React.FC<Props> = (props) => (
  <div className="example">
    <label>
      <Switch
        onChange={props.handlePostStatusData}
        checked={props.checked ? props.checked : false}
        className="react-switch"
        onColor="#8BD8BD"
        offColor="#FF4A55"
        height={34}
        width={109}
        uncheckedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '9',
              fontSize: 14,
              color: 'white',
              padding: 0,
              fontWeight: 600,
            }}
          >
            Deactive
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '9',
              fontSize: 14,
              color: '#273273',
              padding: 0,
              fontWeight: 600,
            }}
          >
            Active
          </div>
        }
      />
    </label>
  </div>
);

export default ToggleSwitch;
