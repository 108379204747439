import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import { disableScrolling } from 'features/util/utility';
import CustomDropDown from 'shared/components/customDropDown';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';

interface AddNewCategoryPopUpProps {
  title: string;
  newCategoryPopUp: boolean;
  showPopUpHandler: (value: boolean) => void;
  handleNewName: (value: string) => void;
  setPath?: string;
  option: any;
  placeholder: string;
  onSubmit: (values: FormikValues) => void;
  isFilter?: boolean;
  filterStatusSearch?: string;
  filterNameSearch?: string;
}
interface OwnProps extends AddNewCategoryPopUpProps, RouteComponentProps {}

const UserListFilterPopUp: React.FC<OwnProps> = (props) => {
  const {
    title,
    newCategoryPopUp,
    showPopUpHandler,
    handleNewName,
    setPath,
    placeholder,
  } = props;

  const options: any = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'DEACTIVE', label: 'DEACTIVE' },
  ];

  const [value, setValue] = useState('');
  const [showModal, setShowModal] = useState(newCategoryPopUp);

  const useOutsideClick = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleModalClosed();
          disableScrolling(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const handleModalClosed = () => {
    setShowModal(!newCategoryPopUp);
    setTimeout(() => {
      showPopUpHandler(false);
    }, 500);
  };

  const closeStatus = () => {
    setValue('');
    handleModalClosed();
    disableScrolling(false);
  };

  const statusSubmit = () => {
    setPath && props.history.push(`${setPath}`);
    handleModalClosed();
    disableScrolling(false);
    handleNewName(value);
  };

  return (
    <div className={`overlay ${showModal ? 'fadeIn' : 'fade-out'}`}>
      <div className="modal-wrapper">
        <div className="new-category-popup" ref={wrapperRef}>
          <p className="category-title">{title}</p>
          <Formik
            initialValues={
              props.isFilter
                ? {
                    statusSearch: props.filterStatusSearch,
                  }
                : {
                    statusSearch: '',
                  }
            }
            onSubmit={props.onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="mt--15">
                  <CustomDropDown
                    options={options}
                    placeholder={placeholder}
                    handleChange={(selectedOptions: any) =>
                      setFieldValue('statusSearch', selectedOptions.value)
                    }
                  />
                </div>
                <div className="btn-wrapper flex align-items--centerjustify-content--between mt--35">
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={closeStatus}
                  >
                    <Translate text={localizationConstants.cancelButton} />
                  </button>
                  <button
                    type="submit"
                    className="add-btn add-category-btn background--green ml--14 font--size__14 line--height__22 font--weight__500 dark--blue-color no--border"
                    onClick={statusSubmit}
                  >
                    <Translate text={localizationConstants.submitButton} />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserListFilterPopUp);
