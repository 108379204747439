import React from 'react';
import * as Sentry from '@sentry/browser';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';

import * as actionTypes from 'store/action-types';
import { axiosInstance } from 'services/http.service';
import { Action, ResponseObj, State } from 'interface';
import { createAction, handleError } from 'features/util/utility';
import { removeAuthData } from 'services/auth.service';
import Notification, {
  notify,
} from 'shared/components/notification/notification';

let resInterceptor: number;
class WithErrorHandler extends React.Component<any> {
  /**
   * add response interceptor before component gets mounted
   * check if response data contains isError = true, if yes, show an error message
   * if response gives a non-200 error code, show error from error data
   * if response contains a message to show, show success notification
   */
  componentDidMount = () => {
    resInterceptor = axiosInstance.interceptors.response.use(
      (res: AxiosResponse<ResponseObj<any>>) => {
        if (res.data && res.data.isError && res.data.message) {
          throw new Error(res.data.message);
        }
        return res;
      },
      (error: AxiosError) => {
        // check if error is having data
        if (error.response && error.response.data && error.response.status) {
          const status = error.response.status;
          const responseData = error.response.data;
          // is http error code is 401, log out of the application
          if (status === 401 || status === 403) {
            removeAuthData();
            notify(handleError(error), 'error');
            this.props.logout();
          } else if (
            responseData.errorMessages &&
            Object.keys(responseData.errorMessages).length
          ) {
            // if error response contains any validation message, fetch it from response, and add error notification
          } else if (
            error.response &&
            responseData &&
            responseData.message &&
            responseData.message !== 'undefined'
          ) {
            notify(handleError(error), 'error');
            Sentry.captureException(handleError(error));
            if (responseData.message.error) {
            } else {
              // if error data contains message field, add error notification
            }
          }
          throw error;
        }
        throw error;
      }
    );
  };

  /**
   * eject response interceptor on when component is about to unmount
   */
  componentWillUnmount = () =>
    axiosInstance.interceptors.response.eject(resInterceptor);
  render() {
    return <Notification />;
  }
}

interface DispatchProps {
  logout: () => void;
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => {
  console.log();
  return {
    logout: () => {
      dispatch(createAction(actionTypes.AUTH_LOGOUT));
    },
  };
};

export default connect<null, DispatchProps, {}, State>(
  null,
  mapDispatchToProps
)(WithErrorHandler);
