import { Action } from 'interface';
import * as actionTypes from 'store/action-types';
import { FilterState } from 'interface/state';
import { IBuilderUser } from 'features/authentication/interface/interface';

interface initialState {
  filter: FilterState;
  modal: { type: string; open: boolean };
  discardModalType: string;
  builderFilterUsers: IBuilderUser[];
}

const initialState: initialState = {
  filter: {} as FilterState,
  modal: { type: '', open: false },
  discardModalType: '',
  builderFilterUsers: [],
};

const reducer = (state: initialState = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.CHANGE_BUILDER_FILTER:      
      return (state = {
        ...state,
        filter: action.payload,
      });
    case actionTypes.OPEN_MODAL:
      return (state = {
        ...state,
        modal: {
          ...state.modal,
          open: true,
          type: action.payload,
        },
      });
    case actionTypes.CLOSE_MODAL:
      return (state = {
        ...state,
        modal: {
          ...state.modal,
          open: false,
          type: '',
        },
      });
    case actionTypes.ADD_DISCARD_MODAL:
      return (state = {
        ...state,
        discardModalType: action.payload,
      });
    case actionTypes.UPDATE_BUILDER_FILTER_DATA:
      return (state = {
        ...state,
        builderFilterUsers: action.payload,
      });
    default:
      return state;
  }
};

export default reducer;
