import React from 'react';
import {
  IBuilderUser,
  IUserData,
} from 'features/authentication/interface/interface';
import * as actionTypes from 'store/action-types';

export interface DropDown {
  label: string | undefined;
  value: string;
  role?: string;
}

export const changeCompanyFilterDetail = (filter: DropDown) => ({
  type: actionTypes.CHANGE_BUILDER_FILTER,
  payload: { ...filter },
});

export const addDiscardModal = (attachment = '') => ({
  type: actionTypes.ADD_DISCARD_MODAL,
  payload: attachment,
});

export const updateCompanyFilterData = (userData: IBuilderUser[]) => ({
  type: actionTypes.UPDATE_BUILDER_FILTER_DATA,
  payload: [...userData],
});

export const updateUserData = (userData: IUserData) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: userData,
});

export const setStandardCustomType = (isCompanyFilter: boolean) => ({
  type: actionTypes.SET_COMPANY_FILTER,
  payload: isCompanyFilter,
});
