import React from 'react';
//@ts-ignore
import Modal from 'react-awesome-modal';
import Translate from '../translate/translate';
import localizationConstants from 'features/util/translation/constant';

interface Props {
  companyPopUp: () => void;
}

export const CompanyDropDownModal: React.FC<Props> = (props) => {
  const { companyPopUp } = props;
  return (
    <div className="pop-up">
      <div className="pop-up-detail">
        <div className="model-view flex justify-content--center align-items--center flex__column">
          <Modal
            visible={true}
            width="500"
            height="200"
            effect="fadeInUp"
            onClickAway={() => {}}
          >
            <h1 className="line--height__30px font--size__22 font--weight__bold dark--blue-color">
              <Translate text={localizationConstants.pleaseSelectCompany} />
            </h1>
            <div className="button mt--35">
              <button
                onClick={() => {
                  companyPopUp();
                }}
              >
                <Translate text={localizationConstants.closeButton} />
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
