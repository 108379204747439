import React from 'react';

interface Props {
  showText?: string;
  getFileName: (file: File) => void;
  fileName: string;
}

const UploadFileType: React.FC<Props> = (props: any) => (
  <div className="input-file--control input-adds--control flex justify-content--start align-items--center">
    <label className="select-file--control pointer">
      <input
        // {...rest}
        type="file"
        className="input-file__input"
        // multiple=''
        onChange={(file: any) => props.getFileName(file)}
        value=""
        // ref=''
        // accept={accept ? accept : 'image/jpeg,image/png,text/plain,video/mp4'}
        accept={'.png,.jpeg,.jpg'}
      />
      <span className="font--size__18 line--height__18 font--weight__400 dark--blue-color width height">
        Choose
      </span>
    </label>
    <p className="selected-value--control font--size__14 line--height__18 font--weight__400 medium--dark--grey-color width height">
      {props.fileName
        ? props.fileName.split('/').pop()
        : props.showText
        ? props.showText
        : 'No file selected'}
    </p>
  </div>
);

export default UploadFileType;
