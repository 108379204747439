export enum CategoryFormEnum {
  'home-inventory' = 1,
  'building-products' = 2,
  'smart-devices' = 3,
  'documents' = 4,
  'secure-folder' = 5,
  'contact' = 6,
}

export enum ActionTypeEnum {
  ViewForm = 'view-form',
  ViewGroupForm = 'view-group-form',
  EditForm = 'edit-form',
  AddIndividualForm = 'add-individual-form',
  ManagerDashboard = 'manager-dashboard',
  OwnerDashboard = 'owner-dashboard',
  UserDashboard = 'user-dashboard',
  userForm = 'user-form',
  ManagerForm = 'add-manager',
  TemplateForm = 'template-form',
  TemplateDashboard = 'template-dashboard',
  AdDashboard = 'setting-dashboard',
  AdForm = 'setting-form',
  NotificationDashboard = 'notification-dashboard',
  NotificationForm = 'notification-form',
  CouponDashboard = 'coupon-dashboard',
  CouponForm = 'coupon-form',
  PropertyInventoryDashboard = 'home-inventory-dashboard',
  PropertyInventoryForm = 'home-inventory-form',
  BuilderDashboard = 'builder-dashboard',
  DocumentDashboard = 'documents-dashboard',
  SmartDeviceDashboard = 'smart-device-dashboard',
  BuilderForm = 'builder-form',
  SmartDeviceForm = 'smart-device-form',
  DocumentForm = 'documents-form',
  ContactDashboard = 'contact-dashboard',
  ContactForm = 'contact-form',
  FolderDashboard = 'close',
  AddFolderForm = 'add-group-form',
  EditFolderForm = 'edit-group-form',
  HouseDashboard = 'house-dashboard',
}

export enum CategoryTypeEnum {
  Standard = 'STANDARD',
  Custom = 'CUSTOM',
  CustomCoupon = 'CUSTOMCOUPON',
}

export enum CurrentRoleEnum {
  SuperAdminRole = 'SUPERADMIN',
  BuilderRole = 'BUILDER',
  ManagerRole = 'MANAGER',
  UserRole = 'USER',
}

export enum StatusEnum {
  Active = 'ACTIVE',
  Deactive = 'DEACTIVE',
}

export enum HouseFolderEnum {
  Folder = 'GROUP',
  House = 'HOUSE',
}

export interface IDocumentLinkProps {
  name: string;
  attachmentId: string;
}

export interface ICategoryFormData {
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  couponHouseId: [];
  couponGroupId: [];
  documentLink: [];
}

export interface ImgStateProps {
  attachmentId: string;
  imagePath: string;
  name: string;
}

export interface DocStateProps {
  attachmentId: string;
  documentPath: string;
  name: string;
}

export interface IBreadCrumDataProps {
  rootName: string;
  rootId: string;
  rootFormId: string;
  currentName: string;
}

export interface IInitialCategoryProps {
  name: string;
  parentId: string;
  categoryForm: string;
}

export interface IHouseListProps {
  groupId: string;
  groupName: string;
  id: string;
  location: string;
  name: string;
  status: string;
  templateId: string;
  type: string;
  userId: string;
}

export interface IFolderListProps {
  companyName: string;
  id: string;
  name: string;
  status: string;
  totalHouses: string;
  type: string;
}

export const couponPath =
  '/coupon/home-inventory/1002174a-42a0-11ea-b77f-2e728ce88125/1/7202174a-42a0-11ea-b77f-2e728ce88125';
