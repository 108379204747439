import React from 'react';

const TermsCondition: React.FC = (props) => (
  <div>
    <h1 className="black--color font--weight__700 font--size__20 pointer line--height__26 mb--10">
      TERMS OF SERVICE
    </h1>
    <p className="black--color font--weight__400 font--size__16 pointer line--height__22">
      Please read these Terms of Service and our Privacy Policy carefully before
      using Welcome Home Online.
    </p>
  </div>
);

export default TermsCondition;
