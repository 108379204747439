/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { FormikValues } from 'formik';
import moment from 'moment';
import { API_CONFIG } from 'constants/api';
import HttpService from 'services/http.service';
import {
  IDropDownProps,
  PaginationDataProps,
} from 'features/UserList/container/constant';
import AdsForm from '../component/adsForm';
import AdsDashboard from '../component/adsDashboard';
import StatusPopUp from 'features/UserList/component/statusPopUp';
import DeletePopUpMenu from 'features/UserList/component/deleteModalPopUp';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { Progress } from 'aws-sdk/lib/request';
import * as AwsService from 'features/util/utility';
import UserListFilterPopUp from 'features/UserList/component/userListFilterPopUp';
import { Action, State } from 'interface';
import { DropDown } from 'constants/interface';
import AdsView from '../adsView/adsview';
import AdsImagePopUp from '../adsView/adsImagePreviewPopUp';
import HelpMessageList from 'shared/components/help/helpMessageList';
import {
  addDiscardModal,
  changeCompanyFilterDetail,
  setStandardCustomType,
} from 'store/actionFolder/actionFolder';
import { notify } from 'shared/components/notification/notification';
import i18n from 'features/util/localization';
import localizationConstants from 'features/util/translation/constant';
import {
  ActionTypeEnum,
  CategoryTypeEnum,
  CurrentRoleEnum,
  IFolderListProps,
  IHouseListProps,
  StatusEnum,
} from 'features/categoryPage/component/constant/constant';
import {
  IPagindationDetail,
  IsortTableProps,
} from 'shared/components/constant';
import { getAuthData } from 'services/auth.service';
import { CompanyDropDownModal } from 'shared/components/modals/companyDropDownModal';

interface UIState {
  action: string;
  accessAdsData: any;
  setData: PaginationDataProps;
  startDate: string;
  endDate: string;
  setStartDate: string;
  setEndDate: string;
  editData: any;
  pagination: IPagindationDetail;
  setSerialNumber: number;
  isLoading: boolean;
  modalState: boolean;
  statusId: string;
  deleteData: boolean;
  deleteId: string;
  ispublished: boolean;
  image: string;
  mobile_image: string;
  newCategoryPopUp: boolean;
  isFilter: boolean;
  filterNameSearch: string;
  resetFilter: boolean;
  filterStatusSearch: string;
  tagNameArray: string[];
  sort: IsortTableProps;
  searchData: string;
  previewData: boolean;
  previewUrl: string;
  helpData: boolean;
  isActionLoading: boolean;
  advertisementType: string;
  houseSelectedValue: string[];
  groupSelectedValue: string[];
  houseDropDownList: string[];
  groupDropDownList: string[];
  filterByBuilderId: string;
  companyPopUp: boolean;
  loadingData: {
    isLoad: boolean;
    type: string;
  };
}

interface OwnProps
  extends RouteComponentProps,
    DispatchProps,
    MapStateToProps {}

class AdvertisementFormHandling extends Component<OwnProps> {
  state: UIState = {
    action: ActionTypeEnum.AdDashboard,
    startDate: new Date().toISOString(),
    setStartDate: '',
    endDate: new Date().toISOString(),
    setEndDate: '',
    editData: {
      name: '',
      position: '',
      link: '',
      image: '',
      ispublished: false,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
    },
    setData: {
      currentPage: 1,
      setPageData: 1,
      totalPageData: 1,
    },
    accessAdsData: {},
    pagination: {
      currentPage: 1,
      totalPages: 1,
      total: 1,
      recordPerPage: 10,
      nextPage: 2,
      remainingCount: 1,
    },
    setSerialNumber: 0,
    isLoading: false,
    modalState: false,
    statusId: '',
    deleteData: false,
    deleteId: '',
    ispublished: false,
    image: '',
    mobile_image: '',
    newCategoryPopUp: false,
    isFilter: false,
    filterNameSearch: '',
    resetFilter: false,
    filterStatusSearch: '',
    tagNameArray: [],
    sort: {
      sortType: '',
      sort: '',
    },
    searchData: '',
    previewData: false,
    previewUrl: '',
    helpData: false,
    isActionLoading: false,
    advertisementType: CategoryTypeEnum.Standard,
    houseSelectedValue: [],
    groupSelectedValue: [],
    houseDropDownList: [],
    groupDropDownList: [],
    filterByBuilderId: '',
    companyPopUp: false,
    loadingData: {
      isLoad: false,
      type: '',
    },
  };

  componentDidMount() {
    const { filter } = this.props;
    if (!!filter.value || filter.label === 'Select Company') {
      this.handleComponentMountData();
      this.props.setCompanyFilter(
        this.state.advertisementType === CategoryTypeEnum.Custom ? true : false
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: UIState) {
    if (
      this.state.pagination.currentPage !== prevState.pagination.currentPage
    ) {
      this.getCurrentActionData();
    }
    const { filter } = this.props;
    if (prevProps.filter.value !== filter.value) {
      this.handleComponentMountData();
    }
  }

  render() {
    const {
      action,
      modalState,
      deleteData,
      setEndDate,
      setStartDate,
      ispublished,
      statusId,
      image,
      mobile_image,
      newCategoryPopUp,
      resetFilter,
      previewData,
      helpData,
      startDate,
      endDate,
      isActionLoading,
      advertisementType,
      companyPopUp,
      loadingData,
    } = this.state;

    return (
      <>
        {(action === ActionTypeEnum.AdForm ||
          action === ActionTypeEnum.EditForm) && (
          <AdsForm
            handleModelState={this.handleModelState}
            onSubmit={this.handleAdsData}
            handleStartDate={this.handleStartDate}
            handleEndDate={this.handleEndDate}
            initialValues={this.state.editData}
            action={action}
            getWebFileName={this.getWebFileName}
            getMobileFileName={this.getMobileFileName}
            editAllData={this.state.editData}
            handleInfoPage={this.handleInfoPage}
            isActionLoading={isActionLoading}
            advertisementType={advertisementType}
            handleMultiChangeForHouse={this.handleMultiChangeForHouse}
            handleMultiChangeForGroup={this.handleMultiChangeForGroup}
            groupDropDownList={this.state.groupDropDownList}
            houseDropDownList={this.state.houseDropDownList}
            loadingData={loadingData}
          />
        )}

        {action === ActionTypeEnum.ViewForm && (
          <AdsView
            handleModelState={this.handleModelState}
            initialValues={this.state.editData}
            handlePreviewPopUpData={this.handlePreviewAdsPopUpData}
            handlePreviewData={this.handlePreviewData}
            advertisementType={advertisementType}
          />
        )}

        {action === ActionTypeEnum.AdDashboard && (
          <AdsDashboard
            handleModelState={this.handleModelState}
            accessAdsData={this.state.accessAdsData}
            handleGetAdsData={this.handleGetAdsData}
            pagination={this.state.pagination}
            handlePageClick={this.handlePageClick}
            datas={this.state.setData}
            setSerialNumber={this.state.setSerialNumber}
            isLoading={this.state.isLoading}
            handleAddStatusPopUpData={this.handleAddStatusPopUpData}
            handleDeletePopUpData={this.handleDeletePopUpData}
            handlePopUpData={this.handlePopUpData}
            resetFilter={resetFilter}
            handleResetFilter={this.handleResetFilter}
            handleSortData={this.handleSortData}
            sort={this.state.sort}
            searchData={this.state.searchData}
            handleSearch={this.handleSearch}
            handleInfoPage={this.handleInfoPage}
            advertisementType={advertisementType}
            handleFilterClick={this.handleFilterClick}
          />
        )}

        {deleteData && (
          <DeletePopUpMenu
            handleReset={this.handleDeleteData}
            handleButtonDenyClick={this.handleButtonDenyClick}
            accessUserData={this.state.accessAdsData}
            handleDeleteData={this.handleDeleteData}
            deleteId={this.state.deleteId}
            isActionLoading={isActionLoading}
          />
        )}

        {modalState && (
          <StatusPopUp
            handleReset={this.handlePostStatusData}
            handlePopUpClick={this.handlePopUpClick}
            accessUserData={ispublished}
            handlePostStatusData={this.handlePostStatusData}
            statusId={statusId}
            isActionLoading={isActionLoading}
          />
        )}
        {newCategoryPopUp && (
          <UserListFilterPopUp
            placeholder={
              this.state.isFilter
                ? this.state.filterStatusSearch || 'STATUS'
                : 'STATUS'
            }
            title="Filter Data"
            newCategoryPopUp={newCategoryPopUp}
            showPopUpHandler={this.handleNewTagNamePopUp}
            handleNewName={this.handleNewName}
            option={[]}
            onSubmit={this.handleFilterSubmit}
            isFilter={this.state.isFilter}
            filterNameSearch={this.state.filterNameSearch}
            filterStatusSearch={this.state.filterStatusSearch}
          />
        )}
        {previewData && (
          <AdsImagePopUp
            handlePreviewAdsPopUpData={this.handlePreviewAdsPopUpData}
            previewUrl={this.state.previewUrl}
          />
        )}
        {helpData && (
          <HelpMessageList
            title="Add New Ads"
            handleInfoDenyClick={this.handleInfoPage}
            description="Here you have the ability to create ads, which will be visible as soon as the owners logs in to the system."
            details="For creating a new ad you need to provide following details:"
            formDetail={[
              'Title',
              'Choose Image : Web Image and Mobile Image',
              'Create Date : Start Date and End Date',
              'Advertise Link',
              'Position',
              'Status',
              // 'Display on',
            ]}
            linkDetails="Note : Every banner is unique according to pages and it's positions (Display on field)."
            link=""
          />
        )}
        {companyPopUp && (
          <CompanyDropDownModal
            companyPopUp={() => this.setState({ companyPopUp: false })}
          />
        )}
      </>
    );
  }
  userData = getAuthData();
  timeOut: any = null;

  handleComponentMountData = () => {
    const selectedBuilderData = this.props.filter;

    this.setState(
      {
        filterByBuilderId: selectedBuilderData.value,
      },
      () => {
        this.getHouseListing({
          builderId: this.state.filterByBuilderId,
        });
        this.getGroupListing({
          builderId: this.state.filterByBuilderId,
        });
        this.getCurrentActionData();
      }
    );
  };

  handleFilterClick = (filterAction: string) => {
    const { filter } = this.props;

    this.setState(
      {
        advertisementType: filterAction,
        companyPopUp:
          !filter.value &&
          this.userData.role === CurrentRoleEnum.SuperAdminRole &&
          this.state.advertisementType === CategoryTypeEnum.Standard,
      },
      () => {
        this.getCurrentActionData();
        this.props.setCompanyFilter(
          this.state.advertisementType === CategoryTypeEnum.Custom
            ? true
            : false
        );
      }
    );
  };

  getGroupListing = (params: any) => {
    this.setState({ isLoading: true });
    const paramsData = {
      ...params,
      statusSearch: StatusEnum.Active,
    };
    HttpService.get(API_CONFIG.path.getGroupData, paramsData)
      .then((response) => {
        let optionList: IDropDownProps[] = [];
        if (response.data.groups && response.data.groups.length) {
          response.data.groups.forEach((group: IFolderListProps) =>
            optionList.push({
              label: group.name,
              value: group.id,
            })
          );
        }
        this.setState({
          groupDropDownList: optionList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getHouseListing = (params: any) => {
    this.setState({ isLoading: true });
    const paramsData = {
      ...params,
      statusSearch: StatusEnum.Active,
    };
    HttpService.get(API_CONFIG.path.getHouseData, paramsData)
      .then((response) => {
        let optionList: IDropDownProps[] = [];
        if (response.data.houses && response.data.houses.length) {
          response.data.houses.forEach((houseList: IHouseListProps) =>
            optionList.push({
              label: houseList.name,
              value: houseList.id,
            })
          );
        }
        this.setState({
          houseDropDownList: optionList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMultiChangeForHouse = (e: any) => {
    let setHouseSelectedValue = Array.isArray(e) ? e.map((x) => x.value) : [];

    this.setState({
      houseSelectedValue: setHouseSelectedValue,
    });
  };

  handleMultiChangeForGroup = (e: any) => {
    let setGroupSelectedValue = Array.isArray(e) ? e.map((x) => x.value) : [];

    this.setState({
      groupSelectedValue: setGroupSelectedValue,
    });
  };

  handleInfoPage = (action: boolean) => {
    this.setState({
      helpData: action,
    });
  };

  handlePreviewData = (url: any) => {
    this.setState({
      previewUrl: url,
    });
  };

  handlePreviewAdsPopUpData = (type: boolean) => {
    this.setState({
      previewData: type,
    });
  };

  handleSortData = (col: string) => {
    const reversedSortOrder =
      this.state.sort.sortType === 'asc' ? 'desc' : 'asc';
    this.setState(
      {
        sort: {
          sort: col,
          sortType: col === this.state.sort.sort ? reversedSortOrder : 'asc',
        },
      },
      () => this.getCurrentActionData()
    );
  };

  handleNewName = (label: string) => {
    let newTagNameArray = [...this.state.tagNameArray];
    newTagNameArray.push(label);
    this.setState({
      tagNameArray: newTagNameArray,
    });
  };

  handlePopUpData = () => {
    this.setState({ newCategoryPopUp: true });
  };

  handleNewTagNamePopUp = (value: boolean) => {
    this.setState({
      newCategoryPopUp: value,
    });
  };

  handleResetFilter = () => {
    this.setState(
      {
        resetFilter: false,
        filterNameSearch: '',
        filterStatusSearch: '',
        searchData: '',
      },
      () => this.getCurrentActionData()
    );
  };

  handleFilterSubmit = (values: FormikValues) => {
    if (values.nameSearch === '' && values.statusSearch === '') {
      this.setState(
        {
          resetFilter: false,
          filterNameSearch: '',
        },
        () => this.getCurrentActionData()
      );
    } else {
      this.setState(
        {
          isLoading: true,
          isFilter: true,
          filterNameSearch: values.nameSearch,
          filterStatusSearch: values.statusSearch,
          resetFilter: true,
        },
        () => this.getCurrentActionData()
      );
    }
  };

  handleButtonDenyClick = () => {
    this.setState({
      deleteData: false,
    });
  };

  handleStatusPopUpData = (id: string, ispublished: boolean) => {
    this.setState({
      ispublished: ispublished,
      statusId: id,
      modalState: true,
    });
  };

  handleAddStatusPopUpData = (id: string, ispublished: boolean) => {
    this.setState({
      ispublished: ispublished,
      statusId: id,
      modalState: true,
    });
  };

  handleDeletePopUpData = (id: string) => {
    this.setState({
      deleteId: id,
      deleteData: true,
    });
  };

  handlePopUpClick = () => {
    this.setState({
      modalState: false,
    });
  };

  handlePostStatusData = (id: string, status: any) => {
    this.setState({
      isActionLoading: true,
    });
    HttpService.put(`${API_CONFIG.path.adsStatus}/${id}`, {
      ispublished: !status,
    })
      .then(() => {
        const { statusActivatedSuccessfully, statusDeactivatedSuccessfully } =
          localizationConstants;
        notify(
          i18n.t(
            status ? statusDeactivatedSuccessfully : statusActivatedSuccessfully
          ),
          'success'
        );
        this.setState(
          {
            modalState: false,
            isActionLoading: false,
          },
          () => this.getCurrentActionData()
        );
      })
      .catch((error) => {
        console.log(error, 'error');
        this.setState({
          isActionLoading: false,
        });
      });
  };

  handlePageClick = (e: any) => {
    const currentPage = e.selected + 1;
    const { recordPerPage } = this.state.pagination;
    this.getAdsData({ currentPage, recordPerPage });
  };

  handleModelState = (action: string) => {
    this.setState(
      {
        action: action,
      },
      () => this.getCurrentActionData()
    );
  };

  handleStartDate = (value: Date) => {
    let setDate = moment(value.toString()).locale('en').format();

    const setDateFormat = setDate.split('T')[0] + ' ' + setDate.split('T')[1];
    this.setState({ startDate: value, setStartDate: setDateFormat });
  };

  handleEndDate = (value: Date) => {
    let setDate = moment(value.toString()).locale('en').format();
    const setDateFormat = setDate.split('T')[0] + ' ' + setDate.split('T')[1];
    this.setState({ endDate: value, setEndDate: setDateFormat });
  };

  handleSearch = (value: string) => {
    const WAIT_INTERVAL = 1000;
    this.setState({
      searchData: value,
      filterNameSearch: value,
      isFilter: true,
    });
    clearTimeout(this.timeOut);
    if (value.length > 3) {
      this.setState({
        resetFilter: true,
      });
      this.timeOut = setTimeout(
        () => this.getCurrentActionData(),
        WAIT_INTERVAL
      );
    } else if (value.length < 1) {
      this.setState({
        resetFilter: false,
      });
      this.timeOut = setTimeout(
        () => this.getCurrentActionData(),
        WAIT_INTERVAL
      );
    }
  };

  getCurrentActionData = () => {
    const { currentPage, recordPerPage } = this.state.pagination;
    this.getAdsData({ currentPage, recordPerPage });
  };

  getAdsData = (params: any = {}) => {
    this.setState({ isLoading: true });
    const selectedBuilderData = this.props.filter;

    const adsParams = {
      ...params,
      ...this.state.sort,
      nameSearch: this.state.filterNameSearch,
      statusSearch: this.state.filterStatusSearch,
      builderId: selectedBuilderData.value,
      bannerType: this.state.advertisementType,
    };
    if (this.state.advertisementType === CategoryTypeEnum.Standard) {
      delete adsParams.builderId;
    }
    HttpService.get(API_CONFIG.path.getCustomAds, adsParams)

      .then((response) => {
        let data: any = response.data.currentPage * response.data.recordPerPage;
        let setPageShowNumber =
          response.data.recordPerPage * response.data.currentPage -
          (response.data.recordPerPage - response.data.banners.length);
        let serialNumber =
          response.data.recordPerPage * response.data.currentPage - 9;

        const remainingData = response.data;
        if (
          response.data.currentPage === 1 &&
          response.data.banners.length === 0
        ) {
          const copyData = { ...remainingData };
          delete copyData.banners;
          this.setState({
            pagination: copyData,
          });
        } else if (response.data.banners.length > 0) {
          const copyData = { ...remainingData };
          delete copyData.banners;
          this.setState({
            pagination: copyData,
          });
        } else {
          const copyData = {
            ...remainingData,
            currentPage: response.data.currentPage - 1,
          };
          delete copyData.banners;
          this.setState({
            pagination: copyData,
          });
        }
        this.setState({
          accessAdsData: response.data.banners,
          setData: {
            currentPage:
              (response.data.currentPage - 1) * response.data.recordPerPage + 1,
            setPageData: data,
            totalPageData: setPageShowNumber,
          },
          setSerialNumber: serialNumber,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  handleAdsData = (values: FormikValues) => {
    this.setState({ isLoading: true });
    this.handlePostAdsData(
      values,
      this.state.action === ActionTypeEnum.EditForm
        ? `${API_CONFIG.path.updateCustomAds}/${values.id}`
        : `${API_CONFIG.path.postCustomAds}`,
      this.state.action !== ActionTypeEnum.EditForm
    );
  };

  handlePostAdsData = (values: FormikValues, url: string, isPost: boolean) => {
    const selectedBuilderData = this.props.filter;
    this.setState({
      isActionLoading: true,
    });
    (isPost ? HttpService.post : HttpService.put)(url, {
      name: values.name,
      position: values.position,
      link: values.link,
      image: this.state.image,
      ispublished: values.ispublished,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      // displayPage: values.displayPage,
      displayPage: 'Landing Page',
      mobile_image: this.state.mobile_image,
      groupId: values.groupId,
      houseId: values.houseId,
      userId:
        this.state.advertisementType === CategoryTypeEnum.Standard
          ? ''
          : selectedBuilderData.value,
    })
      .then((response) => {
        const { adAddedsuccessfully, adUpdatedSuccessfully } =
          localizationConstants;
        notify(
          i18n.t(
            this.state.action === ActionTypeEnum.EditForm
              ? adUpdatedSuccessfully
              : adAddedsuccessfully
          ),
          'success'
        );
        this.props.updateDiscardModal();
        this.setState({
          action: ActionTypeEnum.AdDashboard,
          isLoading: false,
          isActionLoading: false,
        });
        this.getCurrentActionData();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isActionLoading: false,
        });
      });
  };

  handleGetAdsData = (id: string, type: string = ActionTypeEnum.EditForm) => {
    this.setState({ isLoading: true });
    HttpService.get(`${API_CONFIG.path.editCustomAds}/${id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          editData: {
            name: data.name,
            position: data.position,
            link: data.link,
            image: data.image,
            ispublished: data.ispublished,
            startDate: data.startDate,
            endDate: data.endDate,
            id: data.id,
            displayPage: data.displayPage,
            isLoading: false,
            mobile_image: data.mobile_image,
            groupId: data.groupId,
            houseId: data.houseId,
          },
          image: data.image,
          // mobile_image: data.mobile_image.split('/').pop(),
          mobile_image: data.mobile_image,
          action: type,
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  handleDeleteData = (id: string) => {
    this.setState({
      isActionLoading: true,
    });
    HttpService.deleteRequest(`${API_CONFIG.path.deleteCustomAds}/${id}`)
      .then(() => {
        notify(i18n.t(localizationConstants.adDeletedSuccessfully), 'success');
        this.getCurrentActionData();
        this.setState({
          deleteData: false,
          isActionLoading: false,
        });
      })
      .catch((error) => {
        console.log('error', error);
        this.setState({
          isActionLoading: false,
        });
      });
  };

  handleUploadFile = (file: File, type = '') => {
    if (type === 'image' || 'mobile_image') {
      this.setState({ loadingData: { isLoad: true, type: type } });
    }
    HttpService.get(API_CONFIG.path.uploadFile)
      .then((response) => {
        try {
          AwsService.updateAwsConfig({
            bucket: response.data.bucket,
            AccessKeyId: response.data.credentials.AccessKeyId,
            SecretAccessKey: response.data.credentials.SecretAccessKey,
            SessionToken: response.data.credentials.SessionToken,
            Expiration: response.data.credentials.Expiration,
            region: response.data.region,
          });
          const target_Key = file.name;

          const uploadParams: any = AwsService.getFileUploadParams(
            response.data.bucket,
            file,
            target_Key
          );
          this.uploadFile(AwsService.manageUpload(uploadParams)).then((url) => {
            if (type === 'image') {
              this.setState({
                image: url,
                loadingData: {
                  isLoad: false,
                  type: 'image',
                },
              });
            }
            if (type === 'mobile_image') {
              this.setState({
                mobile_image: url,
                loadingData: {
                  isLoad: false,
                  type: 'mobile_image',
                },
              });
            }
          });
        } catch (error) {
          console.log('error', error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  uploadFile = (uploadToS3: ManagedUpload): Promise<string> =>
    new Promise((resolve, reject) => {
      uploadToS3
        .on('httpUploadProgress', (progress: Progress) => {
          console.log(progress);
        })
        .send((errS3: Error, done: { Location: string; key: string }) => {
          if (!errS3) {
            resolve(done.Location);
          } else {
            console.log(errS3, 'errs3');
            reject(errS3);
          }
        });
    });

  getWebFileName = (e: any, type = '') => {
    const image = e.target.files[0];
    setTimeout(() => {
      this.handleUploadFile(image, type);
    }, 200);
  };

  getMobileFileName = (e: any, type = '') => {
    const image = e.target.files[0];
    this.handleUploadFile(image, type);
  };
}

interface MapStateToProps {
  filter: DropDown;
  isCompanyFilter: boolean;
}

interface DispatchProps {
  updateBuilderFilter: (filter: DropDown) => void;
  updateDiscardModal: () => void;
  setCompanyFilter: (isCompanyFilter: boolean) => void;
}

const mapStateToProps = (state: State): MapStateToProps => ({
  filter: state.landingPageState.filter,
  isCompanyFilter: state.notificationState.isCompanyFilter,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
  updateBuilderFilter: (filter: DropDown) =>
    dispatch(changeCompanyFilterDetail({ ...filter })),
  updateDiscardModal: () => dispatch(addDiscardModal('')),
  setCompanyFilter: (isCompanyFilter: boolean) =>
    dispatch(setStandardCustomType(isCompanyFilter)),
});

export default connect<MapStateToProps, any, {}, State>(
  mapStateToProps,
  mapDispatchToProps
)(AdvertisementFormHandling);
