import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  text: string;
}

const Translate: any = (props: Props) => {
  const { t } = useTranslation();
  return t(props.text);
};

export default Translate;
