// import { ThunkDispatch } from 'redux-thunk';
import * as AWS from 'aws-sdk';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { Action } from 'interface';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { Buffer } from 'buffer';
/**
 * @name getS3UploadParams - generates s3-upload parameters
 * @param file - file
 * @param id - ID
 * @param bucket - bucket
 */

// import { Action, State, NotificationStateProps, ModalActions, SelectOptions } from '../interface';

let timer: NodeJS.Timeout;

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
// export const createAction = (ACTION: string, data: any = null): Action => ({
//     type: ACTION,
//     payload: data
// });

/**
 * create loading selector
 * @param actions - actions to dispatch
 */
// export const createLoadingSelector = (actions: string[]) => (state: State) =>
//     // returns true only when all actions is not loading
//     _(actions).some((action: string) => _.get(state, `loading.api.${action}`))
//     ;

/**
 * create error selector
 * @param actions - actions to dispatch
 */
// export const createErrorMessageSelector = (actions: string[]) => (state: State) =>
//     // returns the first error messages for actions
//     // * We assume when any request fails on a page that
//     //   requires multiple API calls, we shows the first error
//     _(actions)
//         .map((action: string) => _.get(state, `error.api.${action}`))
//         .compact()
//         .first() || ''
//     ;

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
// export const dispatchActionAfterTime = (dispatch: ThunkDispatch<{}, {}, Action>, action: string, time: number = 100) => {
//     setTimeout(() => {
//         dispatch(createAction(action));
//     }, time);
// };

/**
 * returns actions to dispatch to add the notification
 * @param dispatch - dispatch object
 */
// export const getNotificationProps = (dispatch: ThunkDispatch<{}, {}, Action>): NotificationStateProps => ({
//     addNotification: (message: string, type: string) => dispatch(createAction(actionTypes.ADD_NOTIFICATION, { message, type }))
// });

export const validateImage = (file: File) => {
  if (file.type.includes('image') && !file.type.includes('gif')) {
    return true;
  } else {
    return false;
  }
};

/**
 * returns actions to dispatch to open/close modal popup
 * @param dispatch - dispatch object
 */
// export const getModalActions = (dispatch: ThunkDispatch<{}, {}, Action>): ModalActions => ({
//     openModal: modalType => dispatch(createAction(actionTypes.OPEN_MODAL, modalType)),
//     closeModal: () => dispatch(createAction(actionTypes.CLOSE_MODAL))
// });

export const debounce = (func: any, wait = 500) => {
  let h: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => func(...args), wait);
  };
};

export const parseQuery = (queryString: string) => {
  const query: any = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export const customDecodeURI = (url: string) => {
  let encodedURI = decodeURIComponent(url);
  encodedURI = encodedURI.replace(/#/g, '%23');
  return encodedURI;
};

export const resetTimer = () => {
  clearTimeout(timer);
};

// export const convertDropdownValueToArray = (selectedValue: string): SelectOptions[] => {
//     const values = selectedValue.split(',');
//     const data = [];
//     for (const value of values) {
//         const temp = {
//             label: value,
//             value: value
//         };
//         data.push(temp);
//     }
//     return data;
// };

export const formatChips = (chips: number) =>
  `${Number(chips.toFixed(0)).toLocaleString('de-DE')}`;
export const formatPrice = (chips: number) =>
  `${Number(chips.toFixed(2)).toLocaleString('de-DE')}`;

export const currencyFormatter = new Intl.NumberFormat('eu-EU', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

export const windowScroll = (
  value: number,
  position: 'top' | 'bottom' = 'top',
  behavior: 'auto' | 'smooth' = 'smooth'
) =>
  window.scrollTo({
    [position]: value,
    behavior: behavior,
  });

export const disableScrolling = (value: boolean) => {
  //to enable
  if (value) {
    document.body.style.width = '100%';
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'auto';
  }
  //to disbale
  else {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.body.style.width = '';
    document.body.style.overflowY = '';
    document.body.style.position = '';
  }
};

export const getS3UploadParams = (
  bucket: string,
  file: File,
  fileid: string,
  key: string
): AwsParamsSchema => {
  console.log('in');

  return {
    Body: file,
    Bucket: bucket,
    ContentType: file.type,
    Key: key,
    Metadata: {
      fileid: fileid,
    },
  };
};

export const getFileUploadParams = (
  bucket: string,
  file: File,
  key: string
): AwsFileParamsSchema => {
  console.log('');

  return {
    Body: file,
    Bucket: bucket,
    ContentType: file.type,
    Key: key,
  };
};

export const getFileUploadParamsWithMetaData = (
  bucket: string,
  file: File,
  key: string,
  metaData: any
): AwsFileParamsSchema => {
  console.log('');

  return {
    Body: file,
    Bucket: bucket,
    ContentType: file.type,
    Key: key,
  };
};

/**
 * @name updateAwsConfig - updates aws configuration
 * @param awsCreds - credentials retrieved from server
 */
export const updateAwsConfig = (awsCreds: AwsCredentials) => {
  AWS.config.update({
    credentials: {
      accessKeyId: awsCreds.AccessKeyId,
      secretAccessKey: awsCreds.SecretAccessKey,
      sessionToken: awsCreds.SessionToken,
    },
    region: awsCreds.region,
    httpOptions: {
      timeout: 0,
    },
  });
  return AWS;
};

/**
 * @name manageUpload - returns a managedUpload object
 * @param params - aws s3 upload parameters
 */
export const manageUpload = (params: AwsParamsSchema) => {
  console.log('');

  return new AWS.S3.ManagedUpload({
    partSize: 5 * 1024 * 1024,
    params: params,
  });
};

/**
 * @name manageFileUpload - returns a managedUpload object
 * @param params - aws s3 upload parameters
 */
export const manageFileUpload = (params: AwsFileParamsSchema) => {
  console.log('');

  return new AWS.S3.ManagedUpload({
    partSize: 5 * 1024 * 1024,
    params: params,
  });
};

export interface AwsCredentials {
  bucket: string;
  AccessKeyId: string;
  SecretAccessKey: string;
  SessionToken: string;
  Expiration: Date;
  region: string;
}
export interface AwsParamsSchema {
  Key: string;
  ContentType: string;
  Body: File;
  Bucket: string;
  Metadata: {
    fileid: string;
  };
}
export interface AwsFileParamsSchema {
  Key: string;
  ContentType: string;
  Body: File;
  Bucket: string;
}

export const createAction = (ACTION: string, data: any = null): Action => {
  console.log();
  return {
    type: ACTION,
    payload: data,
  };
};

export const handleError = (error: AxiosError) => {
  const isError = error.response && error.response.data;
  if (isError) {
    return error.response?.data.message;
  }
  return 'Something went wrong';
};

export const isDiscardModal = (values: FormikValues) => {
  const isDiscardModal = Object.keys(values).map((value) =>
    Array.isArray(values[value]) ? values[value].length > 0 : !!values[value]
  );
  return isDiscardModal.includes(true);
};

export const convertDateToIsoFormat = (
  dateValue: Date,
  convertString?: boolean
) => {
  const formatedValue = convertString
    ? String(
        moment(new Date(dateValue).toISOString()).format('MM/DD/YYYY HH:mm')
      )
    : moment(dateValue).format('MM-DD-YYYY HH:mm');
  return formatedValue;
};

export const quillTextEditor = (
  value: string | '',
  converEncryptToDecript?: boolean
) => {
  const textareaValue = converEncryptToDecript
    ? Buffer.from(value, 'utf8').toString('base64')
    : Buffer.from(value, 'base64').toString();
  return textareaValue;
};

export const getHouseId = (currentPath: string | '', templateId: string) =>
  currentPath.includes('group') || currentPath.includes('house')
    ? templateId || ''
    : '';

export const getTemplateId = (currentPath: string | '', templateId: string) =>
  currentPath.includes('template') ? templateId || '' : '';

export const getCouponId = (currentPath: string, historyState: any) =>
  currentPath.includes('coupon') ? historyState[5] || '' : '';

export const checkDocumentIsValid = (doc: File) => {
  const value = doc === undefined || doc.size > 50e6;
  return value;
};

export const checkImageIsValid = (image: File) => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const value =
    image === undefined ||
    image.size > 10e6 ||
    !allowedTypes.includes(image.type);
  return value;
};
