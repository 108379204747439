import React from 'react';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';

const { adTitle, startDate, endDate, status, action } = localizationConstants;

export const adsTableHead = [
  {
    id: '#',
    classname:
      'user-table--serialno font--size__18 black--color font--weight__500',
    key: 'id',
    sort: false,
  },
  {
    id: <Translate text={adTitle} />,
    classname:
      'user-table--template__name font--size__18 black--color font--weight__500',
    key: 'NAME',
    sort: true,
  },
  {
    id: <Translate text={startDate} />,
    classname:
      'user-table--start-date font--size__18 black--color font--weight__500',
    key: 'STARTDATE',
    sort: true,
  },
  {
    id: <Translate text={endDate} />,
    classname:
      'user-table--end-date font--size__18 black--color font--weight__500',
    key: 'ENDDATE',
    sort: true,
  },
  {
    id: <Translate text={status} />,
    classname:
      'user-table--status font--size__18 black--color font--weight__500',
    key: 'status',
    sort: false,
  },
  {
    id: <Translate text={action} />,
    classname:
      'user-table--action font--size__18 black--color font--weight__500',
    key: 'action',
    sort: false,
  },
];
