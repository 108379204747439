import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PrivacyPolicy from './privacyPolicy';
import TermsCondition from './termsCondition';

interface TermsDataProps {
  name: string;
  path: string;
  classname: string;
}

const TermsAndPrivacy: React.FC = (props) => {
  const [activationLink, setActivationLink] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const termsData = [
    {
      name: 'Terms & Conditions',
      path: '/static/terms-condition',
      classname: '',
    },
    {
      name: 'Privacy Policy',
      path: '/static/privacy-policy',
      classname: 'mt--10',
    },
  ];

  const handleClick = (link: string) => {
    setActivationLink(link);
    history.push(link);
  };

  useEffect(() => {
    const pathname = get(history, 'location.pathname', '');
    setActivationLink(pathname);
  }, [pathname]);

  return (
    <div className="container terms-privacy--wrapper height">
      <div className="terms-privacy flex align-items--start height">
        <div className="side-bar--link width">
          {termsData.map((data: TermsDataProps, index: number) => (
            <div className="side-bar--wrapper" key={index}>
              <ul
                className={`font--size__18 pointer line--height__22 ${
                  activationLink === data.path
                    ? 'primary--blue font--weight__700'
                    : 'black--color font--weight__500'
                }`}
              >
                <li
                  onClick={() => handleClick(data.path)}
                  className={data.classname}
                >
                  {data.name}
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div className="detail width">
          {pathname === '/static/terms-condition' && <TermsCondition />}
          {pathname === '/static/privacy-policy' && <PrivacyPolicy />}
        </div>
      </div>
    </div>
  );
};

export default TermsAndPrivacy;
