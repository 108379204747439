import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import WithErrorHandler from 'hoc/withErrorHandler/withErrorhandler';
import ErrorBoundary from 'hoc/errorBoundary/errorBoundary';
import { HELP_URL } from 'constants/index';
import { I18nextProvider } from 'react-i18next';
import i18n from 'features/util/localization';

const currentPath = window.location.origin;

if (currentPath === (HELP_URL as string)) {
  Sentry.init({
    dsn: 'https://d9d79e7987804e5db1cc2dc48bb00074@o4504138888511488.ingest.sentry.io/4504138891460608',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <WithErrorHandler />
      <ErrorBoundary>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
