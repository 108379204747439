import en from './en';

export interface LocalizationKeys {
  // Company Page
  company: string;
  companies: string;
  propertyManagers: string;
  propertyManager: string;
  homeOwners: string;
  homeOwner: string;
  dashboard: string;
  totalCompany: string;
  totalCompanies: string;
  totalManager: string;
  totalManagers: string;
  totalOwner: string;
  totalOwners: string;
  totalFolder: string;
  totalFolders: string;
  totalHouse: string;
  totalHouses: string;
  addNewCompany: string;
  addCompany: string;
  editCompany: string;
  addCompanySuccessfully: string;
  viewCompany: string;
  viewPropertyManager: string;
  viewHomeOwner: string;
  house: string;
  houses: string;
  folder: string;
  folders: string;
  allPropertyAccess: string;
  allFolderAccess: string;
  email: string;
  companyName: string;
  userName: string;
  communicationEmail: string;
  firstName: string;
  lastName: string;
  clearFilter: string;
  noDataFound: string;
  help: string;
  view: string;
  edit: string;
  deleteData: string;
  name: string;
  status: string;
  action: string;
  cancelButton: string;
  submitButton: string;
  updateButton: string;
  closeButton: string;
  yesButton: string;
  noButton: string;
  areYousureToChangeStatus: string;
  areYouSureToDelete: string;
  addManager: string;
  editManager: string;
  pleaseSelectCompany: string;
  houseName: string;
  editHomeOwner: string;
  //template page
  template: string;
  templates: string;
  templateName: string;
  addTemplate: string;
  editTemplate: string;
  notification: string;
  areYouSuretoOverrideTheChanges: string;
  templateUpdatedsuccessfully: string;
  companyAddedSuccessfully: string;
  companyUpdatedSuccessfully: string;
  companyDeletedSuccessfully: string;
  statusDeactivatedSuccessfully: string;
  statusActivatedSuccessfully: string;
  managerUpdatedSuccessfully: string;
  managerDeletedSuccessfully: string;
  managerAddedSuccessfully: string;
  homeOwnerUpdatedSuccessfully: string;
  homeOwnerDeletedSuccessfully: string;
  categoryAddedSuccessfully: string;
  categoryUpdatedSuccessfully: string;
  templateAddedSuccessfully: string;
  templateDeletedSuccessfully: string;
  pleaseSelectCompanyFromDropdown: string;
  //Manage Ads Page
  ads: string;
  ad: string;
  addAd: string;
  editAd: string;
  manageAds: string;
  viewAd: string;
  adTitle: string;
  startDate: string;
  endDate: string;
  link: string;
  webImage: string;
  mobileImage: string;
  displayImage: string;
  position: string;
  displayPage: string;
  adUpdatedSuccessfully: string;
  adAddedsuccessfully: string;
  adDeletedSuccessfully: string;
  //Notification Page
  notifications: string;
  addNotification: string;
  standard: string;
  custom: string;
  frequency: string;
  title: string;
  addStandardNotification: string;
  addCustomNotification: string;
  editStandardNotification: string;
  editCustomNotification: string;
  createOneTime: string;
  createSchedule: string;
  thisEvent: string;
  thisAndFollowingEvents: string;
  viewStandardNotification: string;
  viewCustomNotification: string;
  notificationUpdatedSuccessfully: string;
  notificationAddedSuccessfully: string;
  notificationDeletedSuccessfully: string;
  description: string;
  coupons: string;
  managers: string;
  owners: string;
  //accountPage
  myAccount: string;
  logout: string;
  editProfile: string;
  changePassword: string;
  role: string;
  saveChanges: string;
  maximumFileSizeAllowed10Mb: string;
  managedBy: string;
  profileUpdatedSuccessfully: string;
  passwordHasBeenChangedSuccessfully: string;
  //coupon page
  back: string;
  createCouponCategory: string;
  addNew: string;
  formDataDeletedSuccessfully: string;
  categoryDeletedSuccessfully: string;
  dataUpdatedSuccessfully: string;
  dataAddedSuccessfully: string;
  addStandardCoupon: string;
  editStandardCoupon: string;
  addCustomCoupon: string;
  editCustomCoupon: string;
  deleteLink: string;
  addLink: string;
  basicDetails: string;
  viewAll: string;
  documentDetails: string;
  add: string;
  addNewTag: string;
  addImage: string;
  addDocument: string;
  maximumFileSizeAllowed50Mb: string;
  tagDetails: string;
  addTag: string;
  viewStandardCoupon: string;
  viewCustomCoupon: string;
  //authentication pages
  logInToYourCompanyAdminArea: string;
  newHere: string;
  createACompany: string;
  forgotPassword: string;
  login: string;
  allRightsReserved: string;
  welcomeHomeOnline: string;
  termsAndConditions: string;
  privacySettings: string;
  createYourCompanyNow: string;
  alreadyHaveACompany: string;
  iAgreeToThe: string;
  signUp: string;
  logIn: string;
  anEmailHasBeenSentSuccessfully: string;
  resetPassword: string;
  sendMail: string;
  reSendEmail: string;
  passwordHasBeenResetSuccessfully: string;
  thankyouForSignUp: string;
  ifYouDontFindEmailInInboxThenCheckJunkSpam: string;
  pleaseCheckYourEmailVerifyAccount: string;
  //category Form
  addPropertyInventory: string;
  editPropertyInventory: string;
  viewPropertyInventory: string;
  addConstructionProducts: string;
  editConstructionProducts: string;
  viewBuildingProducts: string;
  addDocuments: string;
  editDocuments: string;
  viewDocuments: string;
  addSmartHomeDevices: string;
  editSmartHomeDevices: string;
  viewSmartDevice: string;
  addSecureFolder: string;
  editSecureFolder: string;
  viewSecureFolder: string;
  addContact: string;
  editContact: string;
  note: string;
  viewContactDetail: string;
  contactNumber: string;
  companyEmail: string;
  tagDetail: string;
  links: string;
  viewImages: string;
  viewImage: string;
  viewDocument: string;
  //folder module
  folderAddedSuccessfully: string;
  folderDeletedSuccessfully: string;
  allTemplatePropertiesAppliedSuccessfully: string;
  addNewFolder: string;
  coupon: string;
  addHousesExistingFolder: string;
  editFolder: string;
  addFolder: string;
  updateFolder: string;
  next: string;
  addHouses: string;
  updateHouses: string;
  previous: string;
  houseLocation: string;
  ownerDetail: string;
  companyDetail: string;
  viewPastOwnerDetails: string;
  managerDetail: string;
  addDetailsForTemplate: string;
  addDetailsForHouse: string;
  createNewCategory: string;
  pastHomeOwnerDetail: string;
  folderName: string;
  houseCount: string;
  addStandardAdvertisement: string;
  editStandardAdvertisement: string;
  addCustomAdvertisement: string;
  editCustomAdvertisement: string;
  viewStandardAdvertisement: string;
  viewCustomAdvertisement: string;
}
const localizationConstants = {} as LocalizationKeys;

for (const key in en) {
  localizationConstants[key as typeOf] = key;
}

export type typeOf = 'dashboard';

export default localizationConstants;
