import React from 'react';
import { couponPath } from 'features/categoryPage/component/constant/constant';
import i18n from 'features/util/localization';
import localizationConstants from 'features/util/translation/constant';
import {
  CompanyIcon,
  CouponIcon,
  DashBoardIcon,
  FolderIcon,
  HouseIcon,
  ManageAdsIcon,
  NotifitionIcon,
  OwnersIcon,
  PropertyManagerIcon,
  TemplateIcon,
} from 'shared/components/icons';
const {
  dashboard,
  companies,
  managers,
  owners,
  templates,
  folders,
  houses,
  manageAds,
  coupons,
  notifications,
} = localizationConstants;
export const sidebarList = [
  {
    id: 1,
    title: i18n.t(dashboard),
    logo: <DashBoardIcon className="dashboard--icon" />,
    path: '/dashboard',
    role: ['SUPERADMIN', 'MANAGER', 'BUILDER', 'USER'],
  },
  {
    id: 2,
    title: i18n.t(companies),
    logo: <CompanyIcon className="dashboard--icon" />,
    path: '/user',
    role: ['SUPERADMIN'],
  },
  {
    id: 9,
    title: i18n.t(managers),
    logo: (
      <PropertyManagerIcon className="dashboard--icon manager-icon--stroke" />
    ),
    path: '/manager',
    role: ['SUPERADMIN', 'BUILDER'],
  },
  {
    id: 10,
    title: i18n.t(owners),
    logo: <OwnersIcon className="dashboard--icon" />,
    path: '/owner',
    role: ['SUPERADMIN', 'MANAGER', 'BUILDER', 'USER'],
  },
  {
    id: 5,
    title: i18n.t(templates),
    logo: <TemplateIcon className="dashboard--icon" />,
    path: '/template',
    role: ['SUPERADMIN', 'MANAGER', 'BUILDER', 'USER'],
  },
  {
    id: 3,
    title: i18n.t(folders),
    logo: <FolderIcon className="dashboard--icon" />,
    path: '/group',
    role: ['SUPERADMIN', 'MANAGER', 'BUILDER', 'USER'],
  },
  {
    id: 4,
    title: i18n.t(houses),
    logo: <HouseIcon className="dashboard--icon" />,
    path: '/house',
    role: ['SUPERADMIN', 'MANAGER', 'BUILDER', 'USER'],
  },
  // {
  //   id: 6,
  //   title: i18n.t(manageAds),
  //   logo: <ManageAdsIcon className="dashboard--icon" />,
  //   path: '/manage-ads',
  //   role: ['SUPERADMIN'],
  // },
  {
    id: 11,
    title: i18n.t(manageAds),
    logo: <ManageAdsIcon className="dashboard--icon" />,
    path: '/advertisement',
    role: ['SUPERADMIN'],
  },
  {
    id: 7,
    title: i18n.t(coupons),
    logo: <CouponIcon className="dashboard--icon" />,
    path: couponPath,
    role: ['SUPERADMIN'],
  },
  {
    id: 8,
    title: i18n.t(notifications),
    logo: <NotifitionIcon className="dashboard--icon" />,
    path: '/notification',
    role: ['SUPERADMIN', 'BUILDER'],
  },
];
