import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumbs } from 'shared/components/icons';
import { convertDateToIsoFormat } from 'features/util/utility';
import {
  ActionTypeEnum,
  CategoryTypeEnum,
  StatusEnum,
} from 'features/categoryPage/component/constant/constant';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';

interface Props {
  handleModelState: (action: string) => void;
  initialValues: any;
  handlePreviewPopUpData: (type: boolean) => void;
  handlePreviewData: (url: string) => void;
  advertisementType: string;
}

const AdsView: React.FC<Props> = (props) => {
  const {
    initialValues,
    handlePreviewData,
    handlePreviewPopUpData,
    handleModelState,
    advertisementType,
  } = props;
  const {
    manageAds,
    closeButton,
    displayPage,
    title,
    link,
    position,
    status,
    startDate,
    endDate,
    webImage,
    mobileImage,
    houses,
    house,
    folder,
    folders,
    viewCustomAdvertisement,
    viewStandardAdvertisement,
  } = localizationConstants;
  const { t } = useTranslation();
  const handleImagePreviewData = (image: any) => {
    handlePreviewData(image);
    handlePreviewPopUpData(true);
  };

  const handleBackNavigation = () => {
    handleModelState(ActionTypeEnum.AdDashboard);
  };

  const titleAndLinkArray = [
    {
      title: t(title),
      value: initialValues.name,
    },
    {
      title: t(link),
      value: initialValues.link,
    },
  ];

  const potitonAndStatusArray = [
    {
      title: t(position),
      value: initialValues.position,
    },
    {
      title: t(status),
      value:
        initialValues.ispublished === true
          ? StatusEnum.Active
          : StatusEnum.Deactive,
    },
  ];

  const startAndEndDateArray = [
    {
      title: t(startDate),
      value:
        initialValues.startDate &&
        convertDateToIsoFormat(initialValues.startDate),
    },
    {
      title: t(endDate),
      value:
        initialValues.endDate && convertDateToIsoFormat(initialValues.endDate),
    },
  ];

  const imageArray = [
    { title: t(webImage), value: initialValues.image },
    { title: t(mobileImage), value: initialValues.mobile_image },
  ];

  const getAdDetailView = (
    adDetail: any[],
    className: string,
    showImage = false
  ) => (
    <div className={className}>
      {adDetail.map((data: any, index: number) => (
        <div
          className="flex flex--column width--half"
          key={index + `${index} title`}
        >
          <label className="view-page width font--size__18 line--height__22 dark--black-color font--weight__500">
            {data.title} :
          </label>
          {data.title === 'Link' && (
            <a
              className="font--size__16 line--height__20 dark--black-color font--weight__400 mt--10"
              href={data.value}
              target="_blank"
              rel="noreferrer"
            >
              {data.value}
            </a>
          )}
          {!showImage && data.title !== 'Link' && (
            <span className="text--capitalize font--size__16 line--height__20 dark--black-color font--weight__400 mt--10">
              {data.value}
            </span>
          )}
          {showImage && (
            <div
              className="img-wrapper pointer text--capitalize font--size__16 line--height__20 dark--black-color font--weight__400 mt--10"
              onClick={() => handleImagePreviewData(data.value)}
            >
              <img src={data.value} alt="selected img" title={data.value} />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const detailViewArray = [
    {
      dataSource: titleAndLinkArray,
      className: 'mt--35 flex justify-content--between width mb--20',
      showImg: false,
    },
    {
      dataSource: potitonAndStatusArray,
      className:
        'multi-select--option flex justify-content--between width mb--20',
      showImg: false,
    },
    {
      dataSource: startAndEndDateArray,
      className: 'house-form--field flex justify-content--between flex mb--20',
      showImg: false,
    },
    {
      dataSource: imageArray,
      className: 'house-form--field flex justify-content--between flex mb--20',
      showImg: true,
    },
  ];

  return (
    <div className="dashboard-container">
      <div className="group-form__wrapper">
        <div className="category-header flex align-items--center dark--black-color line--height__22">
          <div
            className="template-link text--underline mr--30 width"
            onClick={handleBackNavigation}
          >
            <span className="primary--black pointer font--weight__700 font--size__24">
              <Translate text={manageAds} />
            </span>
          </div>
          <BreadCrumbs className="mr--30" />
          <div className="add-group--header flex justify-content--between align-items--center width">
            <h4 className="font--size__18 line--height__22 dark--black-color font--weight__500 flex align-items--center">
              <Translate
                text={
                  advertisementType === CategoryTypeEnum.Standard
                    ? viewStandardAdvertisement
                    : viewCustomAdvertisement
                }
              />
            </h4>
          </div>
        </div>

        {detailViewArray.map((details: any) =>
          getAdDetailView(
            details.dataSource,
            details.className,
            details.showImg || false
          )
        )}
        {advertisementType !== CategoryTypeEnum.Standard &&
          (initialValues.houseId?.length > 0 ||
            initialValues.groupId?.length > 0) && (
            <div className="flex justify-content--between width mb--20">
              {initialValues.houseId?.length > 0 && (
                <div className="width--half flex align-items--start flex--column">
                  <label className="font--size__18 line--height__22 dark--black-color font--weight__500">
                    <Translate
                      text={initialValues.houseId?.length > 1 ? houses : house}
                    />
                  </label>
                  <div className=" flex mt--10">
                    {initialValues.houseId?.map((data: any, index: number) => (
                      <div
                        key={index}
                        className="notification-house--detail flex mr--10"
                      >
                        <span className="text--capitalize font--size__16 line--height__20 dark--black-color font--weight__400 ">
                          {data.label}{' '}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {initialValues.groupId?.length > 0 && (
                <div className="width--half flex align-items--start flex--column">
                  <label className="font--size__18 line--height__22 dark--black-color font--weight__500">
                    <Translate
                      text={
                        initialValues.groupId?.length > 1 ? folders : folder
                      }
                    />
                  </label>
                  <div className="flex mt--10">
                    {initialValues.groupId?.map((data: any, index: number) => (
                      <div
                        key={index}
                        className="notification-group--detail mr--10"
                      >
                        <span className="text--capitalize font--size__16 line--height__20 dark--black-color font--weight__400 mt--10 mr--10">
                          {data.label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        <div className="multi-select--option flex flex--column">
          <div className=" width flex align-items--start flex--column">
            <label className="view-page width font--size__18 line--height__22 dark--black-color font--weight__500">
              <Translate text={displayPage} /> :
            </label>
            <span className="text--capitalize font--size__16 line--height__20 dark--black-color font--weight__400 mt--10">
              {initialValues.displayPage}
            </span>
          </div>
        </div>
        <div className="horizontal-lines width" />
        <button
          type="button"
          className="cancel-btn font--weight__500 font--size__18"
          onClick={handleBackNavigation}
        >
          <Translate text={closeButton} />
        </button>
      </div>
    </div>
  );
};

export default AdsView;
