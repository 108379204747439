import React from 'react';
import { ErrorMessage, Field } from 'formik';
import FieldErrorMessage from './fieldErrorMessage';

interface FormProps {
  type: string;
  className?: string;
  placeholder?: string;
  id?: string;
  name: string;
  config: Config;
  setFieldValue?: (field: string, value: any) => void;
  onChange?: (event: any) => void;
  min?: string;
  max?: string;
  onBlur?: (
    event: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  readOnly?: boolean;
  ref?: any;
  onFocus?: any;
  autoFocus?: boolean;
  onClick?: (event: any) => void;
  checked?: boolean;
}

interface Config {
  name: string;
}

const RenderInput: React.FC<FormProps & { field: any }> = (props) => {
  switch (props.type) {
    case 'text':
    case 'email':
      return (
        <>
          <input
            className={`${
              props.className || ''
            } input-text--control font--size__16 font--weight__400 line--height__20 black--color width height`}
            type={props.type}
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            value={getValue(props.field.value)}
            onChange={props.onChange || props.field.onChange}
            onBlur={props.onBlur || props.field.onBlur}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
          />

          <ErrorMessage
            name={props.config.name}
            component={FieldErrorMessage}
          />
        </>
      );

    case 'emailDisable':
      return (
        <>
          <div>
            <input
              className={`${
                props.className || ''
              } input-text--control font--size__16 font--weight__400 line--height__20 black--color width height`}
              type={props.type}
              name={props.name}
              id={props.id}
              placeholder={props.placeholder}
              value={getValue(props.field.value)}
              onChange={props.onChange || props.field.onChange}
              disabled
            />
          </div>

          <ErrorMessage
            name={props.config.name}
            component={FieldErrorMessage}
          />
        </>
      );

    case 'number':
      return (
        <>
          <input
            className={`${
              props.className || ''
            } input-text--control font--size__16 font--weight__400 line--height__20 black--color width height`}
            type={props.type}
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            value={getValue(props.field.value)}
            onChange={props.onChange || props.field.onChange}
            min={props.min}
            max={props.max}
            // autoFocus
          />

          <ErrorMessage
            name={props.config.name}
            component={FieldErrorMessage}
          />
        </>
      );
    case 'categoryText':
      return (
        <>
          <input
            className={`${props.className || ''} category-name`}
            type="text"
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            value={getValue(props.field.value)}
            onChange={props.onChange || props.field.onChange}
            autoFocus={props.autoFocus}
            // ref={inputRef}
            // autoFocus={inputRef.current === document.activeElement}
          />

          <ErrorMessage
            name={props.config.name}
            component={FieldErrorMessage}
          />
        </>
      );

    case 'radio':
      return (
        <>
          <input
            className={`${props.className || ''} category-name`}
            type="radio"
            name={props.name}
            id={props.id}
            onChange={props.onChange}
            checked={props.checked}
          />
        </>
      );

    default:
      return <div>test</div>;
  }
};

const Input: React.FC<FormProps> = (props) => {
  const fieldRender = ({ field }: { field: any }) => (
    <RenderInput {...props} field={field} />
  );
  return (
    <Field name={props.name} render={fieldRender} className={props.className} />
  );
};

export default Input;

const getValue = (value: string | number) => {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
};
