import React, { useEffect, useState } from "react";

import formImg from "assets/images/home.png";

interface Props {
  children: any;
}

const PublicLayout: React.FC<Props> = (props: any) => {
  const [showLayout, setHomeName] = useState(false);

  useEffect(() => {
    setHomeName(!window.location.pathname.includes("welcome"));
  }, [window.location.pathname]);

  return (
    <div className="login-wrapper">
      <div className="login-detail">
        <div
          className={`${
            showLayout
              ? "width--half position--relative public-img-wrapper"
              : "hide"
          }`}
        >
          <img className="form-img" src={formImg} alt="logo" />
        </div>
        <div className={`content-wrapper ${!showLayout ? "width" : ""}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
