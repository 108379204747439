import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { CloseIcon, Logo, OpenCloseToggle } from 'shared/components/icons';
import { sidebarList } from './sidebarList';
import { State } from 'interface';
import { createAction } from 'features/util/utility';
import * as actionTypes from 'store/action-types';
import { HeaderModalState } from './constant/constant';
import { couponPath } from 'features/categoryPage/component/constant/constant';

interface Props {
  openSideBar: () => void;
  userRole: string;
  toggle: boolean;
}

const SideBar: React.FC<Props> = (props) => {
  const { userRole, toggle } = props;
  const [activeLink, setActiveLink] = useState('');
  const discardModal = useSelector(
    (state: State) => state.landingPageState.discardModalType
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleLinkNavigation = (link: string) => {
    if (!!discardModal && link !== pathname) {
      dispatch(createAction(actionTypes.ADD_DISCARD_MODAL, link));
      dispatch(createAction(actionTypes.OPEN_MODAL, HeaderModalState.Discard));
      return;
    }
    setActiveLink(link);
    history.push(link);
  };

  useEffect(() => {
    const pathname = get(history, 'location.pathname', '');
    const currentLink = pathname.split('/');
    const couponLink = couponPath;
    setActiveLink(
      pathname === couponLink ? couponLink : `/${currentLink[1] || ''}`
    );
  }, [pathname, discardModal]);

  return (
    <div className={`${toggle ? 'side-panel' : 'sidebar-close--toggle'}`}>
      <div className="side-logo--section flex position--relative">
        <Logo className="side-logo" />
        <div className="side-toggle" onClick={props.openSideBar}>
          {toggle ? (
            <OpenCloseToggle className="position--absolute pointer" />
          ) : (
            <CloseIcon className="position--absolute pointer" />
          )}
        </div>
      </div>
      <div className="side--menu">
        <ul>
          {sidebarList
            .filter((roleUser) => roleUser.role.includes(userRole))
            .map((value, index) => {
              const { id, title, logo, path } = value;

              return (
                <li key={id}>
                  <div
                    key={index + `sidebar ${index}`}
                    onClick={() => handleLinkNavigation(path)}
                    className={`flex justify__content--center align__items--center pointer ${
                      activeLink === path ? 'active-sidebar-link' : ''
                    }`}
                  >
                    <div className="side--menu-wrapper flex width justify-content--center align-items--center">
                      <div className="side--menu-line"></div>
                      <div
                        className="side--menu-list flex width align-items--center"
                        title={title}
                      >
                        <div className="sidebar-icons">{logo}</div>
                        <h4 className="font--weight__500 font--size__14 line--height__17">
                          {title}
                        </h4>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
