import React, { useState } from 'react';
//@ts-ignore
import Modal from 'react-awesome-modal';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';

interface Props {
  className?: string;
  button?: string;
  handleReset: (id: string, status: string) => void;
  handlePopUpClick: () => void;
  handlePostStatusData: (id: string, status: string) => void;
  accessUserData: any;
  statusId: string;
  currentRole?: string;
  isActionLoading?: boolean;
}

const StatusPopUp: React.FC<Props> = (props) => {
  const { isActionLoading } = props;
  const [visible, setVisible] = useState(true);
  const handleCloseModel = () => {
    setVisible(true);
  };
  const { areYousureToChangeStatus, yesButton, noButton } =
    localizationConstants;

  return (
    <div className="pop-up">
      <div className="pop-up-detail">
        <div className="model-view flexjustify-content--center align-items--center flex__column">
          <Modal
            visible={visible}
            width="400"
            height="250"
            effect="fadeInUp"
            onClickAway={handleCloseModel}
          >
            {
              <h1 className="line--height__30px font--size__22 font--weight__bold dark--blue-color text--center mb--20">
                <Translate text={areYousureToChangeStatus} />
              </h1>
            }
            {/* <p className='line--height__30px font--size__18 font--weight__bold dark--blue-color mb--30'>Are you sure want to delete user?</p> */}
            <div className="button flex justify-content--center align-items--center ">
              <button className="delete-data" onClick={props.handlePopUpClick}>
                <Translate text={noButton} />
              </button>
              <button
                className="flex justify-content--center align-items--center pl--10 pr--10"
                onClick={() =>
                  props.handlePostStatusData(
                    props.statusId,
                    props.accessUserData
                  )
                }
                disabled={isActionLoading}
              >
                <Translate text={yesButton} />
                {isActionLoading && <div className="login-loader ml--10" />}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default StatusPopUp;
