import React from 'react';
import Loader from '../loader/loader';

export const TableEmptyContainer = (props: { colSpan?: number }) => (
  <tr>
    <td colSpan={props.colSpan}>
      <Loader />
    </td>
  </tr>
);

export const SimpleLoader = () => <Loader />;
