import React, { CSSProperties } from 'react';
import DropDownArrow from 'assets/images/DropDownArrow.png';
import Select from 'react-select';

const MultiSelectDropDown = (props: any) => {
  const customStyle: any = {
    option: (base: CSSProperties, state: any) => ({
      ...base,
      maxWidth: '582px',
      borderBottom: '1px solid #e7e7e7',
      cursor: 'pointer',
      color: '#444a51',
      padding: '20px',
      backgroundColor: state.isSelected ? '#e7e7e7' : '',
      ':active': {
        backgroundColor: '#e7e7e7',
      },
      ':hover': {
        backgroundColor: '#e7e7e7',
      },
      ':focus': {
        backgroundColor: '#e7e7e7',
        outline: 0,
      },
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      maxWidth: '582px',
      zIndex: 99,
      // position: "relative"
    }),
    menuList: (base: CSSProperties) => ({
      ...base,
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      cursor: 'pointer',
    }),
    input: (base: CSSProperties) => ({
      ...base,
      cursor: 'pointer',
    }),
    placeholder: (base: CSSProperties) => ({
      ...base,
      color: '#969696',
      fontWeight: 400,
      fontSize: '0.9375rem',
      innerHTML: 'Select Template',
    }),
    indicatorsContainer: (base: CSSProperties) => ({
      ...base,
      svg: {
        zIndex: -1,
        display: 'none',
      },
      backgroundImage: ` url(${DropDownArrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '17px 12px',
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      color: '#666666',
      padding: '0px',
      // border: "1px solid #c4c4c4"
    }),
    indicatorSeparator: (base: CSSProperties) => ({
      ...base,
      display: 'none',
    }),
    multiValue: (base: CSSProperties) => ({
      ...base,
      // backgroundColor: "red",
      padding: '4px',
      borderRadius: '6px',
    }),
    multiValueRemove: (base: CSSProperties) => ({
      ...base,
      // backgroundColor: "red",
      padding: '4px',
      borderRadius: '6px',
    }),
    control: () => ({
      // none of react-selects styles are passed to <View />
      display: 'flex',
      maxWidth: '582px',
      height: '60px !important',
      overflow: 'none',
      border: '1px solid #c4c4c4',
      padding: '0px 18px',
      marginTop: '5px',
      backgroundColor: '#ffffff',
      color: '#444a51',
      fontSize: '1rem',
      borderRadius: '10px',
      cursor: 'pointer',
      fontWeight: '400',
      lineHeight: '16px',
      div: {
        overflowX: 'hidden',
        overflowY: 'initial',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
    }),
    singleValue: (base: CSSProperties, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...base, opacity: opacity, transition: transition };
    },
  };
  return (
    <Select
      // defaultValue={[colourOptions[2], colourOptions[3]]}
      // onChange={(selectedOptions) => props.handleChange(selectedOptions)}
      isMulti
      name="colors"
      options={props.options}
      value={props.options.filter((obj: { value: any }) =>
        props.selectedValue.includes(obj.value)
      )}
      className={`basic-multi-select form-dropdown--control` || props.className}
      classNamePrefix="select"
      styles={customStyle}
      onChange={(value) => props.handleChange(value)}
      placeholder={
        <div className="font--size__16 font--weight__400 line--height__16 text--grey">
          {props.placeholder}
        </div>
      }
    />
    // <Select options={props.options} className="form-dropdown--control" styles={customStyle} isSearchable={false} placeholder={<div className='font--size__16 font--weight__400 line--height__16 text--grey'>Select Template</div>} />
  );
};

export default MultiSelectDropDown;
