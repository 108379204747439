import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import Modal from 'react-awesome-modal';
import localizationConstants from 'features/util/translation/constant';
import Translate from 'shared/components/translate/translate';

interface Props {
  className?: string;
  button?: string;
  handleReset: (id: string) => void;
  handleButtonDenyClick: () => void;
  handleDeleteData: (id: string) => void;
  accessUserData?: any;
  deleteId: string;
  isActionLoading?: boolean;
}

const DeletePopUpMenu: React.FC<Props> = (props) => {
  const { isActionLoading } = props;
  const { areYouSureToDelete, yesButton, noButton } = localizationConstants;
  const [visible, setVisible] = useState(true);
  const handleCloseModel = () => {
    setVisible(true);
  };

  const history = useHistory();

  return (
    <div className="pop-up">
      <div className="pop-up-detail">
        <div className="model-view flexjustify-content--center align-items--center flex__column">
          <Modal
            visible={visible}
            width="400"
            height="250"
            effect="fadeInUp"
            onClickAway={handleCloseModel}
          >
            <p className="line--height__30px font--size__22 font--weight__bold dark--blue-color mb--20">
              <Translate text={areYouSureToDelete} />
            </p>

            {history.location.pathname === '/group' && (
              <>
                <span className="line--height__22 font--size__18 font--weight__700 dark--blue-color mb--10">
                  Note:
                </span>
                <p className="folder--note line--height__18 font--size__14 font--weight__400 dark--blue-color mb--20 text--center">
                  This will delete folder and move all houses under it to
                  default folder and all managers having access to this folder
                  will be given access to all individual houses inside the
                  folder.
                </p>
              </>
            )}
            <div className="button flex align-items--center">
              <button
                className="delete-data"
                onClick={props.handleButtonDenyClick}
              >
                <Translate text={noButton} />
              </button>
              <button
                className="flex justify-content--center align-items--center pl--10 pr--10"
                onClick={() => props.handleDeleteData(props.deleteId)}
                disabled={isActionLoading}
              >
                <Translate text={yesButton} />
                {isActionLoading && <div className="login-loader ml--10" />}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DeletePopUpMenu;
