import React from 'react';
import { useHistory } from 'react-router-dom';
import { CloseLogo } from '../icons';

interface Props {
  title: string;
  handleInfoDenyClick: (action: boolean) => void;
  description: string;
  details: string;
  formDetail: string[];
  linkDetails: string;
  link: string;
  steps?: string;
  stepsData?: string;
}

const HelpMessageList: React.FC<Props> = (props) => {
  const {
    title,
    handleInfoDenyClick,
    description,
    details,
    formDetail,
    linkDetails,
    link,
    steps,
    stepsData,
  } = props;
  const history = useHistory();
  const historyState = history.location.pathname.split('/');
  return (
    <div className="help--wrapper">
      <div className="help-data--list p--20">
        <div className="flex justify-content--end align-items--center">
          <h4 className="width font--size__24 dark--black-color font--weight__bold line--height__29px flex">
            Help
          </h4>
          <button
            className="bg--transparent no--border"
            onClick={() => handleInfoDenyClick(false)}
          >
            <CloseLogo className="help-close--icon" />
          </button>
        </div>
        <div className="mt--30">
          <h1 className="font--size__18 dark--blue-color font--weight__bold line--height__29px text--start">
            {title}
          </h1>
          <p className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30">
            {description}
          </p>
          <p className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30">
            {details}
          </p>
          <ul className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30">
            {formDetail.map((data: string, index: number) => (
              <li key={index}>{data}</li>
            ))}
          </ul>
          <p className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30">
            {steps}
          </p>
          <p className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30">
            {stepsData}
          </p>
          <p className="font--size__16 dark--black-color font--weight__400 line--height__22px text--start mt--30 mb--20">
            {linkDetails}
          </p>
          {(historyState[1] === 'user' ||
            historyState[1] === 'manager' ||
            historyState[1] === 'notification') && (
            <div className="flex align-items--center">
              <h1 className="mr--10 font--size__18 dark--blue-color font--weight__bold line--height__29px text--start">
                Link:
              </h1>
              <a
                href={link}
                className=" dark--blue-color text--underline"
                target={'_blank'}
                rel="noreferrer"
              >
                {link}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpMessageList;
