import { ErrorMessage } from 'formik';
import React from 'react';

interface Props {
  name: string;
}

const ErrorMessageHandler: React.FC<Props> = (props) => {
  const { name } = props;
  return (
    <div>
      <ErrorMessage name={name}>
        {(msg: string) => <div className="show--error">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default ErrorMessageHandler;
