import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  DropDownIcon,
  Logout,
  ProfilePlaceholder,
} from 'shared/components/icons';
import Sampleimg from 'assets/images/avatar.png';
import { HeaderModalState } from './constant/constant';
import CustomDropDown from 'shared/components/customDropDown';
import CryptoJS from 'crypto-js';
import HttpService from 'services/http.service';
import { API_CONFIG } from 'constants/api';
import { Formik } from 'formik';
import { getAuthData, removeAuthData } from 'services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'interface';
import { createAction } from 'features/util/utility';
import * as actionTypes from 'store/action-types';
import { DiscardModal } from 'shared/components/modals/discardModal';
import {
  IBuilderUser,
  IUserData,
} from 'features/authentication/interface/interface';
import { CurrentRoleEnum } from 'features/categoryPage/component/constant/constant';
import Translate from 'shared/components/translate/translate';
import localizationConstants from 'features/util/translation/constant';

interface Props {
  open?: boolean;
  userData: IUserData;
}
const SearchBarHeader: React.FC<Props> = (props: any) => {
  const userData = getAuthData();
  const history = useHistory();
  const historyState = history.location.pathname.split('/') || [];
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [builderIdDropDownList, setBuilderIdDropDownList] = useState<any>([
    // { label: 'Select Company', value: '' },
  ]);
  const [builderValue, setBuilderValue] = useState<string>('');
  const { pathname } = useLocation();
  const state = useSelector((state: State) => state.landingPageState);
  const isCompanyFilter = useSelector(
    (state: State) => state.notificationState.isCompanyFilter
  );
  const updateUserData = useSelector((state: State) => state.userData.userData);
  const [isView, setIsView] = useState(false);

  const dispatch = useDispatch();
  const KEY: string = 'adsfghjkla2312safaaszAS';

  const getBuilderData = () => {
    try {
      const data = localStorage.builderDataLabel;
      const builderId = localStorage.builderDataId;
      const builderRole = localStorage.builderRole;
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const idBytes = CryptoJS.AES.decrypt(builderId.toString(), KEY);
        const decryptedBuilderId = JSON.parse(
          idBytes.toString(CryptoJS.enc.Utf8)
        );
        const builderRoleBytes = CryptoJS.AES.decrypt(
          builderRole.toString(),
          KEY
        );
        const decryptedBuilderRole = JSON.parse(
          builderRoleBytes.toString(CryptoJS.enc.Utf8)
        );
        setBuilderValue(decryptedData);
        const currentBuilder = {
          label: decryptedData || 'Select Company',
          value: decryptedBuilderId || '',
          role: decryptedBuilderRole || '',
        };
        dispatch(
          createAction(actionTypes.CHANGE_BUILDER_FILTER, { ...currentBuilder })
        );
        return decryptedData;
      } else {
        const currentBuilder = {
          label: 'Select Company',
          value: '',
          role: '',
        };
        dispatch(
          createAction(actionTypes.CHANGE_BUILDER_FILTER, { ...currentBuilder })
        );
        return false;
      }
    } catch (e) {
      const currentBuilder = {
        label: 'Select Company',
        value: '',
        role: '',
      };
      dispatch(
        createAction(actionTypes.CHANGE_BUILDER_FILTER, { ...currentBuilder })
      );
      return false;
    }
  };

  const setBuilderData = (data: any): void => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data.value), KEY);
    const builderTextLabel = CryptoJS.AES.encrypt(
      JSON.stringify(data.label),
      KEY
    );
    const builderRole = CryptoJS.AES.encrypt(JSON.stringify(data.role), KEY);
    localStorage.setItem('builderDataId', cipherText.toString());
    localStorage.setItem('builderDataLabel', builderTextLabel.toString());
    localStorage.setItem('builderRole', builderRole.toString());
    dispatch(createAction(actionTypes.CHANGE_BUILDER_FILTER, { ...data }));
  };

  const useOutsideClick = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
          setShowNotification(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const logout = () => {
    localStorage.removeItem('builderDataId');
    localStorage.removeItem('builderDataLabel');
    removeAuthData();
    setShowMenu(false);
    dispatch(createAction(actionTypes.AUTH_LOGOUT));
  };

  const setBuilderIdList = (Array: IBuilderUser[]) => {
    let newListArray: any = [{ label: 'Select Company', value: '' }];
    if (
      pathname === '/user' ||
      pathname === '/template' ||
      pathname === '/manager' ||
      pathname === '/owner' ||
      pathname === '/group' ||
      pathname === '/house' ||
      historyState[1] === 'coupon' ||
      pathname === '/notification' ||
      pathname === '/advertisement'
    ) {
      newListArray = [];
    }

    Array.map((builderIdList: any) => {
      newListArray.push({
        // label:
        //   builderIdList.firstName +
        //   ' ' +
        //   builderIdList.lastName +
        //   '(' +
        //   builderIdList.companyName +
        //   ')',
        label: builderIdList.companyName,
        value: builderIdList.id,
        role: builderIdList.role,
      });
    });
    setBuilderIdDropDownList(newListArray);
  };

  const getParentIdListData = () => {
    HttpService.get(API_CONFIG.path.getUser, {
      currentPage: 1,
      recordPerPage: 100000000000000,
    })
      .then((response) => {
        dispatch(
          createAction(actionTypes.UPDATE_BUILDER_FILTER_DATA, [
            ...response.data.users,
          ])
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    getParentIdListData();
    getBuilderData();
    handleGetUserDetail();
  }, []);

  useEffect(() => {
    if (state.builderFilterUsers.length > 0) {
      setBuilderIdList(state.builderFilterUsers);
    }
  }, [state.builderFilterUsers, pathname]);

  const handleGetUserDetail = () => {
    HttpService.get(API_CONFIG.path.accountDetail)
      .then((response) => {
        //@ts-ignore
        window.__insp = window.__insp || [];
        //@ts-ignore
        __insp.push([
          'tagSession',
          {
            email: response.data.email,
            userId: response.data.id,
            role: response.data.role,
          },
        ]);
        dispatch(
          createAction(actionTypes.UPDATE_USER_DATA, { ...response.data })
        );
        dispatch(createAction(actionTypes.AUTH_LOGIN));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue('builderId', state.filter);
      const cipherText = CryptoJS.AES.encrypt(
        JSON.stringify(state.filter.value),
        KEY
      );
      const builderTextLabel = CryptoJS.AES.encrypt(
        JSON.stringify(state.filter.label),
        KEY
      );
      const builderRole = CryptoJS.AES.encrypt(
        JSON.stringify(state.filter.role),
        KEY
      );
      localStorage.setItem('builderDataId', cipherText.toString());
      localStorage.setItem('builderDataLabel', builderTextLabel.toString());
      localStorage.setItem('builderRole', builderRole.toString());
      // setBuilderData(state.filter)
      setBuilderValue(state.filter.label);
    }
  }, [state.filter]);

  const hideCompanyDropdownRoute = [
    'user',
    'manage-ads',
    'coupon',
    'edit-profile',
    'change-password',
    'notification',
    'advertisement',
  ];

  useEffect(() => {
    setIsView(!hideCompanyDropdownRoute.includes(pathname.split('/')[1]));
  }, [pathname]);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const formikRef: any = useRef();

  return (
    <div
      className="admin-panel flex justify-content--center align-items--center"
      style={!props.open ? { right: -68, width: '100%', paddingRight: 96 } : {}}
    >
      <div className="admin-header flex width height justify-content--between align-items--center">
        {userData.role === CurrentRoleEnum.SuperAdminRole && (
          <>
            {(isView ||
              ((pathname === '/notification' ||
                historyState[1] === 'coupon' ||
                pathname === '/advertisement') &&
                isCompanyFilter)) && (
              <div className="admin-header--searchbar position--relative width">
                <Formik
                  initialValues={{
                    builderId: state.filter.value,
                  }}
                  onSubmit={() => {}}
                  innerRef={formikRef}
                >
                  {({ setFieldValue, values }) => (
                    <form>
                      <div className="builderdropdown flex align-items--center">
                        <h2 className="font--size__18 black--color font--weight__400 line--height__21">
                          <Translate text={localizationConstants.company} />
                        </h2>
                        <div className="builder-custom ml--20">
                          <CustomDropDown
                            options={builderIdDropDownList}
                            value={state.filter}
                            placeholder={
                              builderValue === ''
                                ? 'Select Company'
                                : builderValue
                            }
                            handleChange={(selectedOptions: any) => {
                              setFieldValue('builderId', selectedOptions.value);
                              setBuilderData(selectedOptions);
                            }}
                            className="builder-select"
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </>
        )}
        {(userData.role === CurrentRoleEnum.BuilderRole ||
          userData.role === CurrentRoleEnum.ManagerRole) && (
          <div className="width">
            <h2 className="primary--black text--capitalize">
              {updateUserData.companyName}
            </h2>
          </div>
        )}
        <div className="admin-header--profilebar width flex justify-content--end">
          <div className="admin-header--notificationbar height flex " />
          <div className="admin-header--userprofile height flex align-items--center">
            {props.userData.avatar === null || props.userData.avatar === '' ? (
              <div className="name-img">
                <img
                  src={Sampleimg}
                  className="width height object__fit--cover "
                />
              </div>
            ) : (
              <div className="name-imgs">
                <img
                  src={props.userData.avatar}
                  className="width height object__fit--cover "
                />
              </div>
            )}
            <div
              className="user-name width height flex justify-content--between  align-items--center pointer"
              onClick={() => {
                setShowMenu(true);
                setShowNotification(false);
              }}
              ref={wrapperRef}
            >
              <div>
                <h4 className="font--size__16 black--color font--weight__700 line--height__21 flex">
                  {props.userData.firstName} {props.userData.lastName}
                </h4>
                <p className="font--size__14 black--color font--weight__400 line--height__21 flex">
                  {userData.role === CurrentRoleEnum.BuilderRole
                    ? 'COMPANY'
                    : userData.role}
                </p>
              </div>
              <a
                href="#"
                className="flex justify-content--center align-items--center"
              >
                <DropDownIcon />
              </a>
            </div>
            {showMenu && (
              <div className="options-wrapper" ref={wrapperRef}>
                <div className="menu">
                  <NavLink to="/edit-profile">
                    <li
                      className="menu-item pb--25"
                      onClick={() => setShowMenu(false)}
                    >
                      <div className="icon-wrapper">
                        <ProfilePlaceholder className="person--icon" />
                      </div>
                      <p className="font--size__16 line-height--14 flex align-items--center justify-content--center text--grey font--weight__500">
                        My Account
                      </p>
                    </li>
                  </NavLink>
                  <hr />
                  <li className="menu-item pt--25" onClick={() => logout()}>
                    <div className="icon-wrapper">
                      <Logout className="person--icon" />
                    </div>
                    <p className="font--size__16 line-height--14 flex align-items--center justify-content--center text--grey font--weight__500">
                      Logout
                    </p>
                  </li>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {state.modal.open && state.modal.type === HeaderModalState.Discard && (
        <DiscardModal />
      )}
    </div>
  );
};

export default SearchBarHeader;
