import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { API_BASE_URL } from './index';

export const API_CONFIG = {
  /* eslint-disable no-undef */
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    login: 'auth/login',
    register: 'auth/register',
    forgot: 'password/forgot',
    accountDetail: 'account/me',
    updateAccount: 'account',
    changePassword: 'password/change',
    getUser: 'users',
    postUser: 'users',
    editUser: 'users/update',
    delete: 'users/delete',
    getUserData: 'users',
    logout: 'logout',
    getAds: 'banners/All',
    getAdById: 'banners/byId',
    postAds: 'banners',
    editAds: 'banners/update',
    deleteAds: 'banners/delete',
    getTemplates: 'templates',
    getTemplateById: 'templates/byId',
    postTemplate: 'templates/create',
    editTemplate: 'templates/update',
    deleteTemplate: 'templates/delete',
    getGroupData: 'groups',
    getHouseData: 'houses',
    getHouseEditData: 'houses',
    postHouse: 'houses',
    getManager: 'users/managers',
    getOwner: 'users/owners',
    status: 'users/change_user_status',
    activationToken: 'auth/activation',
    getCategory: 'categories/count',
    postSmartDevice: 'categories-form/create',
    getCategoryFormData: 'categories-form/get/categories',
    editHouse: 'houses/update',
    deleteHouse: 'houses/delete',
    resetPassword: 'password/reset',
    adsStatus: 'banners/update/status',
    houseStatus: 'houses/update/status',
    groupStatus: 'groups/update/status',
    deleteGroup: 'groups/delete',
    getAccountDashboardData: 'account/dashboard',
    resend: 'auth/resend',
    uploadFile: 'storage/credentials',
    getCategoryData: 'categories-form/get',
    postGroup: 'groups',
    updateCategoriesData: 'categories-form/update',
    deleteCategoriesData: 'categories-form/delete',
    postHouseList: 'houses/groups',
    postHouseTemplate: 'houses/update/template',
    postGroupTemplate: 'groups/update/template',
    createCategories: 'categories',
    postTemplateSubmit: 'submit/template',
    houseFinalSubmit: 'houses/final/submit',
    groupFinalSubmit: 'groups/final/submit',
    importHouseData: 'houses/import/housedata',
    subCategories: 'categories',
    getAllParentIdData: 'categories/standard/Custom',
    updateAllCategoryForm: 'categories-form/update',
    deleteTemporaryTemplateData: 'submit/delete',
    templateRollOut: 'rollout/template',
    houseRollOut: 'rollout/house/TemplateToCategoryAndCategoryToTemplate',
    templateStatus: 'templates/update/status',
    switchTemplateRollOut: 'rollout/switchTemplate/forGroupsAndHouse',
    getCoupon: 'coupon/All',
    getCouponById: 'coupon/byId',
    postCoupon: 'coupon',
    allNotification: 'notifications/All',
    viewNotification: 'notifications/view',
    postNotification: 'notifications/create',
    updateNotification: 'notifications',
    getNotificationData: 'notifications/byId',
    deleteNotification: 'notifications/delete',
    notificationStatus: 'notifications/update/status',
    categoryFinalSubmit: 'categories/final/submit',
    categoryJunkDataDelete: 'categories/delete/junk',
    customCategoryDelete: 'categories',
    noRollOutHouse: 'houses/no-rollout',
    houseRelatedDetail: 'houses/houseRelatedDetail',
    getHouseToFolder: 'houses/add/to/folder',
    groupRollOut: 'rollout/groups/houses/with_template_data',
    transferHome: 'transfer-home/owner',
    getCustomAds: 'banners/AllBanners',
    postCustomAds: 'banners/new',
    deleteCustomAds: 'banners/bannerDelete',
    editCustomAds: 'banners/bannerById',
    updateCustomAds: 'banners/updateBanner',
  },
};

export const getUrl = (url: string, params: any = {}): string => {
  Object.keys(params).forEach(
    (key) => (params[key] == null || params[key] === '') && delete params[key]
  );
  if (url.includes('http')) {
    return url;
  }
  let urlString = `${API_BASE_URL}/${url}`;
  if (params && !isEmpty(params)) {
    urlString += `?${queryString.stringify(params)}`;
  }
  return urlString;
};
