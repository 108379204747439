import React from 'react';

// interface Props {
//   className?: string;
//   project?: any;
//   icon?: any;
//   projectId?: any;
//   projectName?: string;
//   status?: any;
//   action?: any;
//   link?: any;
//   style?: any;
// }

interface Props
  extends React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >,
    React.DetailedHTMLProps<
      React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
      HTMLTableHeaderCellElement
    > {
  colSpan?: number;
}

export const Table: React.FC<Props> = (props) => (
  <div className={`${props.className}`}>
    <table cellPadding="0" cellSpacing="0" className={props.className}>
      {props.children}
    </table>
  </div>
);

export const TableHead: React.FC<Props> = (props) => (
  <thead className={`${props.className}`}>{props.children}</thead>
);

export const TableRow: React.FC<Props> = (props) => (
  <tr className={`${props.className}`}>{props.children}</tr>
);

export const TableHeadingCell: React.FC<Props> = (props) => (
  <th className={`${props.className}`} style={props.style}>
    {props.children}
  </th>
);
export const TableBody: React.FC<Props> = (props) => (
  <tbody className={`${props.className}`}>{props.children}</tbody>
);
export const TableCell: React.FC<Props> = (props) => (
  <td className={` ${props.className ? props.className : ''}`}>
    {props.children}
  </td>
);
export const TableCellNotCentered: React.FC<Props> = (props) => (
  <td className={`${props.className ? props.className : ''}`}>
    {props.children}
  </td>
);
export const TableLeftAlignCell: React.FC<Props> = (props) => (
  <td className={`${props.className ? props.className : ''}`}>
    {props.children}
  </td>
);
