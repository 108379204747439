import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationDataProps } from 'features/UserList/container/constant';
import { IPagindationDetail } from '../constant';
import { NextIcon, PreviousIcon } from '../icons';

interface Props {
  datas: PaginationDataProps;
  pagination: IPagindationDetail;
  handlePageClick: (e: any) => void;
}

export const ReactPagindationFile: React.FC<Props> = (props) => {
  const { datas, pagination, handlePageClick } = props;
  return (
    <div className="user-list--pagination width flex justify-content--between align-items--center">
      <div className="page-list">
        <h2 className="medium--dark--grey-color font--weight__400 font--size__14 line--height__17">
          Showing{' '}
          <span className="dark--blue-color font--weight__400 font--size__14 line--height__17">
            {' '}
            {`${datas.currentPage} - ${datas.totalPageData}`}
          </span>{' '}
          from{' '}
          <span className="dark--blue-color font--weight__400 font--size__14 line--height__17">
            {pagination.total}
          </span>{' '}
          data
        </h2>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<NextIcon />}
        onPageChange={handlePageClick}
        breakClassName={'break-me'}
        pageRangeDisplayed={2}
        pageCount={pagination.totalPages}
        containerClassName={
          'pagination-btn flex justify-content--center align-items--center pointer'
        }
        activeClassName={
          'pagination-page--number active flex justify-content--center align-items--center medium--dark--grey-color pointer'
        }
        pageClassName={
          'pagination-page--number flex justify-content--center align-items--center medium--dark--grey-color pointer'
        }
        previousLabel={<PreviousIcon />}
        marginPagesDisplayed={1}
        forcePage={pagination.currentPage - 1}
      />
    </div>
  );
};
