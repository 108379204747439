import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

interface Props {
  values: any;
  handleEndDate: (value: Date) => void;
  setFieldValue: any;
  filterPassedTime?: any;
  readonly?: boolean;
  className?: string;
  date: string;
  name: string;
  placeHolder: string;
}

const DatePickerFile: React.FC<Props> = (props) => {
  const {
    values,
    setFieldValue,
    handleEndDate,
    filterPassedTime,
    readonly,
    className,
    date,
    name,
    placeHolder,
  } = props;
  return (
    <div>
      <DatePicker
        selected={values && new Date(values)}
        value={
          values &&
          String(
            moment(new Date(values).toISOString()).format('MM/DD/YYYY HH:mm')
          )
        }
        name={name}
        onChange={(dates: Date) => {
          handleEndDate(dates);
          setFieldValue(date, dates);
        }}
        placeholderText={placeHolder}
        autoComplete="off"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={20}
        timeCaption="time"
        dropdownMode="select"
        dateFormat="MM/dd/yyyy HH:mm"
        className={`input-text--control font--size__16 font--weight__400 line--height__20 black--color width height pr--20 ${className}`}
        minDate={new Date()}
        filterTime={filterPassedTime}
        readOnly={readonly}
      />
    </div>
  );
};

export default DatePickerFile;
